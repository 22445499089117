import styled from '@emotion/styled';

const TermsAndCoditionsStyle = styled('div')`
    width: 100%;
    border-radius: 8px;
    background: #fff;
    /* shadow */
    box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    padding: 30px 30px 60px 30px;
    page-break-inside: avoid;
    
    .title {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        margin-bottom: 24px;
    }
    .iconLink{
        color: #1a91ff;
        cursor: pointer;
    }
    .link{
        color: #1a91ff;
    }
    .content {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        font-family: 'Lato';
        page-break-inside: avoid;
        > h1 {
            margin-top: 1em;
            color: #2c3e50;
            margin-bottom: 1em;
        }
        > h2 {
            margin-top: 1em;
            color: #2c3e50;
            margin-bottom: 1em;
        }
        > h3 {
            margin-top: 1em;
            color: #2c3e50;
            margin-bottom: 0.5em;
        }
        > p {
            margin-bottom: 1em;
        }
        > ul {
            list-style-type: none;
            padding-left: 1.5em;
        }
        > ul li::before {
            content: '• ';
            color: #2980b9;
        }
    }
    .error {
        margin-top: 45px;
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
    }
`;
export default TermsAndCoditionsStyle;

export const ErrorStyle = styled('div')`
    border-radius: 4px;
    background: #fff;
    box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    padding: 24px;
`;
export const TermsAndCoditionsMainImageStyle = styled('div')`
    width: 100%;
    box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    border-radius: 6px;
    .viewMainImage {
        width: 100%;
        height: 413px;
        margin-bottom: 23px;
    }
    .imgGoflTravel {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }
    @media screen and (max-width: 1200px) {
        .viewMainImage{
            display: none;
        }
    }
`;