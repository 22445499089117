import styled from '@emotion/styled';

interface styleProps {
    modePDF?: any;
}

const StepComponentStyle = styled('div')<styleProps>`
    display: flex;
    margin-top: 35px;
    justify-content: space-between;
    padding-bottom: 42px;
    .nextStepTitle {
        color: #454545;
        font-size: 16.071px;
        font-weight: 700;
        line-height: 22.098px;
    }
    .nextStepDiscription {
        color: #454545;
        font-size: 16.071px;
        font-weight: 400;
        line-height: 22.098px;
        letter-spacing: 0.161px;
    }
    .link {
        color: #1a91ff;
        font-size: 16.071px;
        font-weight: 400;
        line-height: 22.098px;
        letter-spacing: 0.161px;
        text-decoration-line: underline;
        cursor: pointer;
    }
    .groupWhyItem {
        width: 23%;
    }
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding-bottom: 0px;
        .groupWhyItem {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            justify-content: space-between;
        }
        .contentStep {
            width: 83%;
        }
    }
`;

export default StepComponentStyle;

export const StyledModalSendMess = styled('div')`
    margin-top: 20px;
    .btnSendMessage {
        margin-top: 20px;
        width: 100%;
        height: 50px;
        background-color: #12b897;
        font-family: 'Lato';
        font-size: 16px;
        font-weight: 900;
        line-height: 17.867px;
        letter-spacing: 0.32px;
    }
    .btnSendMessage:hover {
        background-color: #12b899 !important;
    }
`;
