import { Popover, Skeleton } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DeltaStatic, Sources } from 'quill';
import { useDispatch, useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import { useLocation } from 'react-router-dom';
import { formatPhoneNumber } from 'src/const/contain';
import { updateQuoteIntroSuccess } from 'src/redux/actions/quoteIntroAction';
import parse from 'html-react-parser';
import ViewQuoteIntroStyle from './style';

interface EditorProps {
    customPropEditor?: any;
    contentEvent?: any;
    contentStandard?: any;
    signinEvent?: any;
    signinStandard?: any;
    lastName?: any;
    nameResConsultant?: any;
    phoneNumber?: any;
    emailResConsultant?: any;
    introNew?: any;
    checkEvent?:any;
    promotionIntro?:any
}

const EditerIntro: React.FC<EditorProps> = ({
    customPropEditor,
    contentEvent,
    contentStandard,
    signinEvent,
    signinStandard,
    lastName,
    nameResConsultant,
    phoneNumber,
    emailResConsultant,
    introNew,
    checkEvent,
    promotionIntro
}) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const isRole = useSelector(roleSelector);
    const [viewToInput, setChangeViewToInput] = useState<boolean>(false);
    const [valueEditor, setValueEditor] = useState<any>('');
    const [maxleng, setMaxLengh] = useState<number>(0);
    const valueEditorRef = useRef(valueEditor);
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const dispatch = useDispatch();

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['link'],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['blockquote', 'code-block'],
        ],
    };

    const formats = [
        'header',
        'link',
        'script',
        'indent',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'list',
        'blockquote',
        'code-block',
    ];


    useEffect(() => {
        const handleClickOutsideUpdateIntro = (event: MouseEvent) => {
            if (
                inputRef.current &&
                !inputRef.current.contains(event.target as Node)
            ) {
                dispatch(updateQuoteIntroSuccess(valueEditorRef.current));
            }
        };

        document.addEventListener('mousedown', handleClickOutsideUpdateIntro);
        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideUpdateIntro,
            );
        };
    }, [dispatch]);

    const introStandar = contentStandard?.split('\r\n').join('<br><br>');
    const introEvent = contentEvent?.split('\r\n').join('<br><br>');
    const promotion = promotionIntro?.split('\r\n').join('<br><br>') || ''

    useEffect(() => {
        setValueEditor(
            introNew
                ? introNew
                : type === 'Event'
                ? introEvent
                : introStandar,
        );
        const totalCharacters = valueEditor?.length;
        setMaxLengh(totalCharacters);
        valueEditorRef.current =
            type === introNew
                ? introNew
                : type === 'Event'
                ? introEvent
                : introStandar;
    }, [contentEvent, contentStandard]);

    const quillRef = useRef<ReactQuill>(null);

    const updateIntro = (
        value: string,
        delta: DeltaStatic,
        source: Sources,
        editor: UnprivilegedEditor,
    ) => {
        const quillEditor = quillRef.current?.getEditor();
        const sanitizeContent = (input: string) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(input, 'text/html');
            const tagsToRemove = ['script', 'img', 'video'];
            tagsToRemove.forEach((tag) => {
                const elements = doc.getElementsByTagName(tag);
                while (elements.length > 0) {
                    elements[0].parentNode?.removeChild(elements[0]);
                }
            });

            return doc.body.innerHTML;
        };
        const sanitizedValue = sanitizeContent(value);
        if (sanitizedValue.length > 1500) {
            const truncatedValue = sanitizedValue.slice(0, 1500);
            const excessLength = sanitizedValue.length - 1500;
            if (excessLength > 0 && quillEditor) {
                quillEditor.deleteText(1500, excessLength);
            }
            setValueEditor(truncatedValue);
            valueEditorRef.current = truncatedValue;
            setMaxLengh(1000);
        } else {
            setValueEditor(sanitizedValue);
            valueEditorRef.current = sanitizedValue;
            if (sanitizedValue.length === 11) {
                setMaxLengh(0);
            } else {
                setMaxLengh(sanitizedValue.length);
            }
        }
    };

    const changerViewToInput = () => {
        setChangeViewToInput(!viewToInput);
    };

    return (
        <ViewQuoteIntroStyle>
            {customPropEditor ? (
                <div className="discriptionGoflTravel">
                    {isRole === 'Edit mode' ? (
                        <div>
                            {viewToInput ? (
                                <div className="editViewInyto">
                                     {viewToInput && (
                                        <div className="areaEdit">
                                            <div className="validateViewMainIntro">
                                                {maxleng}/1500
                                            </div>
                                        </div>
                                    )}
                                    <div className="contentEdit" ref={inputRef}>
                                        <ReactQuill
                                            theme="snow"
                                            modules={modules}
                                            formats={formats}
                                            style={{
                                                width: '100%',
                                                height: '82%',
                                                position: 'relative',
                                                bottom: '0px',
                                            }}
                                            value={valueEditor}
                                            onChange={updateIntro}
                                            ref={quillRef}
                                        />
                                    </div>
                                   
                                </div>
                            ) : (
                                <Popover
                                    placement="rightTop"
                                    content={'Click to edit'}
                                >
                                    <div
                                        className="contentEdit"
                                        onClick={changerViewToInput}
                                    >
                                        <div className="content">
                                            {parse(valueEditor)}
                                        </div>
                                    </div>
                                </Popover>
                            )}
                        </div>
                    ) : (
                        <div className="content">
                            {introNew
                                ? parse(introNew)
                                : type === 'Event'
                                ? parse(introEvent)
                                : parse(introStandar)}
                                
                        </div>
                    )}
                    <div className="content">{parse(promotion)}</div>
                    <div className="endIntroMobile">
                        {(checkEvent === true || type === 'Event') ? signinEvent : signinStandard}
                    </div>
                    <div className="signIn">
                        <div>
                            <div className="endIntroDesktop">
                                {(checkEvent === true || type === 'Event')
                                    ? signinEvent
                                    : signinStandard}
                            </div>
                            <div className="signature">{lastName}</div>
                        </div>
                        <div className="inforSeller">
                            <div className="signatureMobile">{lastName}</div>
                            <div>{nameResConsultant}</div>
                            {phoneNumber && (
                                <div>{formatPhoneNumber(phoneNumber)} </div>
                            )}
                            <div className="mailSeller">
                                {' '}
                                {emailResConsultant}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Skeleton
                    active={true}
                    style={{ marginTop: 16, marginBottom: 16 }}
                />
            )}
        </ViewQuoteIntroStyle>
    );
};

export default EditerIntro;
