import React, { useEffect, useState } from 'react';
import IntroductionGoflTravelStyle from './style';
import Carousel from 'src/components/carouselCustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import StepComponent from './stepsComponent';
import WhatOutGolferSayComponent from './whatOutGolfersSayComponent';
import { useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';
import { formatPhoneNumber, modifyURL } from 'src/const/contain';
import 'react-quill/dist/quill.snow.css';
import { getDetailExpert } from 'src/api/apiGetDetailExpert';
import useQueryApi from 'src/hooks/useApiQuery';
import EditerIntro from 'src/components/quillEditerIntro';
import ItemCarosel from './itemCarousel';
import { getImageApi } from 'src/api/apiGetImage';
import { getImageContentFromExternalIdApi } from 'src/api/apiGetImageAndContentfFromExternalId';
import { ImageExternalContent } from 'src/types';

interface IntroductionGoflTravelProps {
    customProp?: any;
    dataJsonProp?: any;
    image?: string;
    onUpdateValueInput?: (value: any) => void;
}

const IntroductionGoflTravel: React.FC<IntroductionGoflTravelProps> = (
    props,
) => {
    const { customProp, dataJsonProp, image, onUpdateValueInput } = props;
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [mainImage, setMainImage] = useState<any>();
    const type = queryParams.get('type');
    const [introExpert, setIntroExpert] = useState<any>('');
    const [reviewArr, setReviewArray] = useState<any>([]);

    const nameResConsultant = dataJsonProp?.resConsultant?.name;
    const dataRes = dataJsonProp?.resConsultant;
    const lastName = nameResConsultant?.split(' ').slice(0, -1).join(' ');
    const emailResConsultant = dataJsonProp?.resConsultant?.email;
    const phoneNumber = dataJsonProp?.resConsultant?.telephone;
    const avatarConsultant = dataJsonProp?.resConsultant?.image || null;
    const companyName = dataJsonProp?.resConsultant?.companyName;
    let modifiedString = companyName?.replace('Golf ', '');
    modifiedString = modifiedString?.replace('plus', '+');
    const dataJsonImage = dataJsonProp?.mainCourse?.image;
    const externalContentId = dataJsonProp?.mainCourse?.externalContentId || '';
    const tourNameTakeImage: string = dataJsonProp?.tourName || '';
    const words = tourNameTakeImage?.split(' ');
    const firstTwoWords =
        words[0] === 'Masters' ? 'Masters' : `${words[0]} ${words[1]}`;
    //id image tour event
    const defaultImage = customProp?.defaultImage?.sys?.id;
    const idTheOpenImageInContentFullApi: any =
        customProp?.theOpenImage?.sys?.id || null;
    const idRyderCupImage: any = customProp?.theRyderCupImage?.sys?.id || null;
    const idMasterImage: any = customProp?.theMastersImage?.sys?.id || null;
    const idsolheimCupImageImage: any =
        customProp?.solheimCupImage?.sys?.id || null;

    const introParagraph = dataJsonProp?.clientComments?.introParagraph || [];
  
    const promotionalMessage =
        dataJsonProp?.clientComments?.promotionalMessage || [];

    const { data: dataNameResConsultant } = useQueryApi(
        ['nameResConsultant', nameResConsultant],
        () => getDetailExpert(nameResConsultant),
        {
            enabled: !!nameResConsultant,
        },
    );

    const getMappedArrayByCondition = (condition: any) => {
        let theImageToure = '';
        switch (condition) {
            case 'The Open':
                theImageToure = idTheOpenImageInContentFullApi;
                break;
            case 'Ryder Cup':
                theImageToure = idRyderCupImage;
                break;
            case 'Masters':
                theImageToure = idMasterImage;
                break;
            case 'Solheim Cup':
                theImageToure = idsolheimCupImageImage;
                break;
            default:
                return (theImageToure = defaultImage);
        }
        return theImageToure;
    };
    const idImageTour = getMappedArrayByCondition(firstTwoWords);

    const { data: imageTourTheOpenData } = useQueryApi(
        ['image', idImageTour],
        () => getImageApi(idImageTour),
        {
            enabled: !!idImageTour,
        },
    );

    const { data: imageExternalContent } = useQueryApi(
        ['image', externalContentId],
        () => getImageContentFromExternalIdApi(externalContentId),
        {
            enabled: !!externalContentId,
        },
    );

    const exxternalIdImage = (() => {
        const content = imageExternalContent as ImageExternalContent;
        if (
            content?.fields?.cldImages &&
            Array.isArray(content.fields.cldImages) &&
            content.fields.cldImages.length > 0
        ) {
            return content.fields.cldImages[0].url;
        }
        if (
            content?.fields?.images &&
            Array.isArray(content.fields.images) &&
            content.fields.images.length > 0
        ) {
            return content.fields.images[0].url;
        }
        return '';
    })();

    const imageUrlTourTheOpen = (imageTourTheOpenData as any)?.fields?.file?.url
        ? `http:${(imageTourTheOpenData as any)?.fields?.file?.url}`
        : null;

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!exxternalIdImage) {
                setMainImage(image);
            }
        }, 3000);

        if (exxternalIdImage) {
            setMainImage(exxternalIdImage);
        }
        if (dataNameResConsultant) {
            setIntroExpert(dataNameResConsultant);
        }

        if (!dataJsonProp?.reviews) {
            setReviewArray([]);
        } else if (
            dataJsonProp?.reviews?.golf &&
            dataJsonProp?.reviews?.hotel
        ) {
            const combinerReview = [
                ...dataJsonProp?.reviews?.golf,
                ...dataJsonProp?.reviews?.hotel,
            ];
            setReviewArray(combinerReview);
        }

        return () => clearTimeout(timer);
    }, [
        customProp,
        image,
        dataJsonImage,
        type,
        dataNameResConsultant,
        dataJsonProp?.reviews,
    ]);

    const introExpertText =
        introExpert?.fields?.documentation?.content[0]?.content[0]?.value;

    const valueIntroStandard = customProp?.agentIntroStandard?.content || [];
    const combinedTextStandard = Array.isArray(valueIntroStandard)
        ? valueIntroStandard
              .slice(0, -1)
              .map((item: any) => item?.content?.[0]?.value || '')
              .join('\r\n')
        : '';
    const introStandard = `${combinedTextStandard}`;
    const signinStandard =
        Array.isArray(valueIntroStandard) && valueIntroStandard.length > 0
            ? valueIntroStandard[valueIntroStandard.length - 1]?.content?.[0]
                  ?.value || ''
            : '';

    const contentEvent = customProp?.salesAgentIntroTournament.content || [];
    const combinedTextEvent = Array.isArray(contentEvent)
        ? contentEvent
              .slice(0, -1)
              .map((item: any) => item?.content?.[0]?.value || '')
              .join('\r\n')
        : '';
    const introEvent = `${combinedTextEvent}`;
    const signinEvent =
        Array.isArray(contentEvent) && contentEvent.length > 0
            ? contentEvent[contentEvent.length - 1]?.content?.[0]?.value || ''
            : '';

    const introUpdateNew = dataJsonProp?.intro;

    const iconArr: Record<string, string> = {
        whyBookWithImage1: customProp?.whyBookWithImage1?.sys?.id,
        whyBookWithImage2: customProp?.whyBookWithImage2?.sys?.id,
        whyBookWithImage3: customProp?.whyBookWithImage3?.sys?.id,
        whyBookWithImage4: customProp?.whyBookWithImage4?.sys?.id,
    };

    const titleArr: Record<string, string> = {
        whyBookWithYgtTitle1: customProp?.whyBookWithYgtTitle1,
        whyBookWithYgtTitle2: customProp?.whyBookWithYgtTitle2,
        whyBookWithYgtTitle3: customProp?.whyBookWithYgtTitle3,
        whyBookWithYgtTitle4: customProp?.whyBookWithYgtTitle4,
    };

    const discriptionArr: Record<string, string> = {
        whyBookWithYourGolfTravel1: customProp?.whyBookWithYourGolfTravel1,
        whyBookWithYourGolfTravel2: customProp?.whyBookWithYourGolfTravel2,
        whyBookWithYourGolfTravel3: customProp?.whyBookWithYourGolfTravel3,
        whyBookWithYourGolfTravel4: customProp?.whyBookWithYourGolfTravel4,
    };

    const arrWhyContentNew = [1, 2, 3, 4].map((id) => ({
        id,
        icon: iconArr[`whyBookWithImage${id}`],
        title: titleArr[`whyBookWithYgtTitle${id}`],
        discription: discriptionArr[`whyBookWithYourGolfTravel${id}`],
    }));
    const checkEvent = (dataJsonProp as { isEvent: any })?.isEvent;
    const promotionIntro = `${Array.isArray(introParagraph) ? `${introParagraph.join('\r\n')}` : ''}${Array.isArray(promotionalMessage) ? `\r\n<b>${promotionalMessage?.join('\r\n')}</b>`:''}`

    return (
        <IntroductionGoflTravelStyle>
            {(checkEvent === false || type === 'Standard') && (
                <div>
                    {!mainImage ? (
                        <div className="viewMainImage">
                            <Skeleton.Image
                                className="imgGoflTravel"
                                active={true}
                                style={{ width: '100%', height: 413 }}
                            />
                        </div>
                    ) : (
                        <div className="viewMainImage">
                            <img
                                alt=""
                                className="imgGoflTravel"
                                src={modifyURL(mainImage, 1000)}
                            />
                        </div>
                    )}
                </div>
            )}
            {(checkEvent === true || type === 'Event') && (
                <div>
                    {!imageUrlTourTheOpen ? (
                        <div className="viewMainImage">
                            <Skeleton.Image
                                className="imgGoflTravel"
                                active={true}
                                style={{ width: '100%', height: 413 }}
                            />
                        </div>
                    ) : (
                        <div className="viewMainImage">
                            <img
                                alt=""
                                className="imgGoflTravel"
                                src={modifyURL(imageUrlTourTheOpen, 500)}
                            />
                        </div>
                    )}
                </div>
            )}
            <EditerIntro
                customPropEditor={customProp}
                contentEvent={String(introEvent)}
                contentStandard={String(introStandard)}
                signinEvent={signinEvent}
                signinStandard={signinStandard}
                lastName={lastName}
                nameResConsultant={nameResConsultant}
                phoneNumber={phoneNumber}
                emailResConsultant={emailResConsultant}
                introNew={introUpdateNew}
                checkEvent={checkEvent}
                promotionIntro={promotionIntro}
            />
            <div className="viewWhyContent">
                <div className="titleWhyContent">
                    Why book with Your Golf Travel?
                </div>
                <div className="showItemWhy">
                    {arrWhyContentNew.map((item: any, index: any) => {
                        return (
                            <WhatOutGolferSayComponent
                                imageId={item.icon}
                                title={item.title}
                                content={item.discription}
                                key={index}
                            />
                        );
                    })}
                </div>
            </div>
            {reviewArr?.length !== 0 && (
                <div className="carouselView">
                    <div className="titleWhyContent">What our golfers say</div>
                    <div className="carouselDesktop">
                        <Carousel
                            itemsToShow={2}
                            totalLength={reviewArr?.length}
                        >
                            {reviewArr?.map((item: any, index: any) => {
                                return (
                                    <ItemCarosel
                                        dataItemCarosel={item}
                                        key={index}
                                    />
                                );
                            })}
                        </Carousel>
                    </div>
                    <div className="carouselMobile">
                        <Carousel
                            itemsToShow={1}
                            totalLength={reviewArr?.length}
                        >
                            {reviewArr?.map((item: any, index: any) => {
                                return (
                                    <ItemCarosel
                                        dataItemCarosel={item}
                                        key={index}
                                    />
                                );
                            })}
                        </Carousel>
                    </div>
                </div>
            )}
            <div className="carouselView">
                <div className="titleWhyContent">Next steps</div>
                <StepComponent
                    phone={phoneNumber}
                    lastName={nameResConsultant}
                    checkEvent={checkEvent}
                />
            </div>
            <div className="endView">
                <div className="titleWhyContent">
                    About{' '}
                    <span className="nameSeller">{nameResConsultant} </span> -
                    Your Golf Travel Expert
                </div>
                {dataRes ? (
                    <div className="viewEnd">
                        <div className="commentSeller">
                            {introExpertText || ''}
                        </div>
                        <div className="avatarSignView">
                            <div className="avatarSeller">
                                {avatarConsultant ? (
                                    <div className="backgroundAvatar">
                                        <img
                                            alt=""
                                            className="avatarReals"
                                            src={avatarConsultant}
                                        />
                                    </div>
                                ) : (
                                    <FontAwesomeIcon
                                        className="avatar"
                                        icon={faCircleUser}
                                    />
                                )}
                            </div>
                            <div className="signEnd">
                                <div className="signature">
                                    {nameResConsultant}
                                </div>
                                <div>
                                    {phoneNumber && (
                                        <div className="phone">
                                            {formatPhoneNumber(phoneNumber)}
                                        </div>
                                    )}
                                    <div className="email">
                                        {emailResConsultant}
                                    </div>
                                    {companyName && <div>{modifiedString}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="viewEnd">
                        <Skeleton active={true} />
                    </div>
                )}
            </div>
        </IntroductionGoflTravelStyle>
    );
};

export default React.memo(IntroductionGoflTravel);
