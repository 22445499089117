import {
    faBed,
    faCar,
    faFlag,
    faGolfBallTee,
    faMap,
    faPlane,
    faTaxi,
    faTrophy,
    faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { formatCurrency } from 'src/const/contain';

interface ItemPricingBookingProps {
    itemData?: any;
    numberItem?: Number | any;
    priceItem?: Number | any;
    tourName?: any;
    departureDate?: any;
}

const ItemPricingBooking: React.FC<ItemPricingBookingProps> = ({
    itemData,
    numberItem,
    tourName,
    departureDate,
}) => {
    const pricePerPerson = itemData?.items?.price || 0;
    const carHire = itemData?.items?.carHire || [];
    const carHirePickUp = carHire[0]?.pickUp?.location || '';
    const carHireDuration = carHire[0]?.nights || 0;
    const flight = itemData?.items?.flight || [];
    const flightDeparture = flight[0]?.departure?.name || '';
    const flightArrival = flight[0]?.arrival?.name || '';
    const tranfer = itemData?.items?.transfer || null;
    const accom = itemData?.items?.accommodations || [];
    const totalPerson = itemData?.items?.totalPerson || 0;
    const spaPackage = itemData?.items?.accommodations[0]?.hasSpa || false;
    const returnAirportTransfer =
        itemData?.items?.returnAirportTransfer || false;
    const itemGolfDetail = itemData?.items?.golf || [];
    const golfExtra = itemData?.items?.hasGolfExtra || false;
    const flightExtra = itemData?.items?.hasFlightExtra || false;
    const nbrCodeExtra =
        itemData?.items?.accommodations[0]?.extra[0]?.nbrCode || '';
    const unallocatedAccommodation = localStorage.getItem(
        'unallocatedAccommodation',
    );
    const hasTour = itemData?.items?.hasTourEvent || false

    const results = tranfer.map((transfer: any) => {
        if (transfer.carType.includes('Airport')) {
            return 'Airport';
        } else if (transfer.carType.includes('Golf')) {
            return 'Golf';
        } else if (transfer.carType.includes('Hotel')) {
            return 'Hotel';
        } else {
            return '';
        }
    });

    const roundedPricePerPerson = Math.abs(Number(pricePerPerson || 0));

    return (
        <div className="areaGroup">
            <div className="headerGroup">
                {numberItem > 1 ? (
                    <div className="fullNameGroup">
                        <div className="firstNameGroup">{itemData?.group} </div>
                        <div className="lastNameGroup"> (price per person)</div>
                    </div>
                ) : (
                    <div className="fullNameGroup">
                        <div className="firstNameGroup">Price per person</div>
                    </div>
                )}
                {formatCurrency(pricePerPerson) > '0' ? (
                    <div className="priceGroup">
                        £{formatCurrency(pricePerPerson)}
                    </div>
                ) : (
                    <div className="priceGroup">
                        -£{formatCurrency(roundedPricePerPerson)}
                    </div>
                )}
            </div>
            <div className="bodyGroup">
                {hasTour && (
                    <div className="contentInfor">
                        <div className="iconOfGroup">
                            <FontAwesomeIcon icon={faTrophy} />
                        </div>
                        <div>{tourName}</div>
                    </div>
                )}

                {flight?.length > 0 && (
                    <div className="contentInfor">
                        <div className="iconOfGroup">
                            <FontAwesomeIcon icon={faPlane} />
                        </div>
                        <div>
                            {' '}
                            {flight?.length > 1
                                ? 'Return Flights'
                                : 'Flight'}{' '}
                            from {flightDeparture} to {flightArrival}
                        </div>
                    </div>
                )}

                {returnAirportTransfer && (
                    <div className="contentInfor">
                        <div className="iconOfGroup">
                            <FontAwesomeIcon icon={faPlane} />
                        </div>
                        <div> Return airport tranfers</div>
                    </div>
                )}

                {flightExtra && (
                    <div className="contentInfor">
                        <div className="iconOfGroup">
                            <FontAwesomeIcon icon={faPlane} />
                        </div>
                        <div>Flight extras as detailed in your itinerary</div>
                    </div>
                )}

                {carHire?.length > 0 && (
                    <div className="contentInfor">
                        <div className="iconOfGroup">
                            <FontAwesomeIcon icon={faCar} />
                        </div>
                        <div>
                            {' '}
                            Car hire pick-up from {carHirePickUp} for{' '}
                            {carHireDuration}{' '}
                            {Number(carHireDuration) > 1 ? 'days' : 'day'}
                        </div>
                    </div>
                )}

                {unallocatedAccommodation === 'true' && (
                    <div className="contentInfor">
                        <div className="iconOfGroup">
                            <FontAwesomeIcon icon={faBed} />
                        </div>
                        <div>Accommodation as per your itinerary</div>
                    </div>
                )}

                {accom?.length > 0 && (
                    <>
                        {accom?.map((item: any, index: any) => {
                            const extra = item?.extra;
                            const duration = item?.duration;
                            return (
                                <div key={index}>
                                    <div className="contentInfor">
                                        <div className="iconOfGroup">
                                            <FontAwesomeIcon
                                                icon={faUserGroup}
                                            />
                                        </div>
                                        <div>
                                            {totalPerson}{' '}
                                            {Number(totalPerson) === 1 &&
                                                'person'}{' '}
                                            {Number(totalPerson) > 1 &&
                                                'people'}{' '}
                                            arriving on{' '}
                                            {moment(
                                                item?.startDate || 0,
                                            ).format('DD/MM/YYYY')}
                                        </div>
                                    </div>
                                    <div className="contentInfor">
                                        <div className="iconOfGroup">
                                            <FontAwesomeIcon icon={faMap} />
                                        </div>
                                        <div>
                                            {' '}
                                            Staying at {item?.name},{' '}
                                            {item?.roomType}
                                            {/* {item?.boardBasis
                                                ? `, ${item?.boardBasis}`
                                                : ''} */}
                                            {`${
                                                item?.singleOccupancy
                                                    ? ', (Single Occupancy)'
                                                    : ''
                                            }`}
                                        </div>
                                    </div>
                                    <div
                                        className="contentInfor"
                                        // key={indexExtra}
                                    >
                                        <div className="iconOfGroup">
                                            <FontAwesomeIcon icon={faBed} />{' '}
                                        </div>
                                        <div>
                                            {duration}{' '}
                                            {Number(duration) > 1
                                                ? 'Nights'
                                                : 'Night'}
                                            {item?.boardBasis
                                                ? `, ${item?.boardBasis}`
                                                : ''}
                                            {extra?.map(
                                                (
                                                    itemExtras: any,
                                                    indexEx: any,
                                                ) => {
                                                    return (
                                                        <span key={indexEx}>
                                                            {itemExtras?.nbrCode !==
                                                                item?.roomType &&
                                                            itemExtras?.nbrCode !==
                                                                item?.boardBasis
                                                                ? `, ${itemExtras?.nbrCode}`
                                                                : ''}
                                                            {itemExtras
                                                                ?.extraComments[0]
                                                                ? `, ${itemExtras?.extraComments[0]}`
                                                                : ''}
                                                        </span>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}

                {accom?.duration && (
                    <div className="contentInfor">
                        <div className="iconOfGroup">
                            <FontAwesomeIcon icon={faBed} />{' '}
                        </div>
                        <div>
                            {accom?.duration}{' '}
                            {Number(accom?.duration) > 1 ? 'Nights' : 'Night'},{' '}
                            {accom?.boardBasis}
                        </div>
                    </div>
                )}

                {spaPackage && nbrCodeExtra !== 'Spa Package' && (
                    <div className="contentInfor">
                        <div className="iconOfGroup">
                            <FontAwesomeIcon icon={faUserGroup} />
                        </div>
                        <div> Spa package</div>
                    </div>
                )}

                {itemGolfDetail?.map((item: any, index: any) => {
                    return (
                        <div key={index}>
                            <div className="contentInfor">
                                <div className="iconOfGroup">
                                    <FontAwesomeIcon
                                        icon={tourName ? faGolfBallTee : faFlag}
                                    />
                                </div>
                                <div>
                                    {' '}
                                    {item?.rounds}{' '}
                                    {item?.rounds > 1 ? 'Rounds' : 'Round'} of
                                    Golf at {item?.serviceDescription}
                                    {item?.documentDescription
                                        ? `, ${item?.documentDescription}`
                                        : ''}
                                </div>
                            </div>
                        </div>
                    );
                })}

                {golfExtra && (
                    <div className="contentInfor">
                        <div className="iconOfGroup">
                            <FontAwesomeIcon
                                icon={tourName ? faGolfBallTee : faFlag}
                            />
                        </div>
                        <div> Golf extras as detailed in your itinerary</div>
                    </div>
                )}

                {tranfer?.length > 0 && (
                    <div className="contentInfor">
                        <div className="iconOfGroup">
                            <FontAwesomeIcon icon={faTaxi} />
                        </div>
                        <div>
                            {' '}
                            {results[1]}{' '}
                            {results[1] ? 'transfers' : 'Transfers'} as detailed
                            in your itinerary
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ItemPricingBooking;
