import React, { useState } from 'react';
import HotelCardStyle from './style';
import images from 'src/assets/image';
import icons from 'src/assets/icon';
import ModalComponent from '../modalCustom';
import ModalTouristDetination from 'src/pages/QuoteDetailPage/ItineraryInDetail/ModalTouristDestination';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import AgentCommentComponent from '../agentComment';
import { useLocation } from 'react-router-dom';
import { modifyURL } from 'src/const/contain';
import useQueryApi from 'src/hooks/useApiQuery';
import { getImageContentFromExternalIdApi } from 'src/api/apiGetImageAndContentfFromExternalId';
import { ImageExternalContent } from 'src/types';

interface hotelCardProps {
    dataHotel?: any;
    avatarAgent?: any;
    refetchApi?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isLoading?: boolean;
    itineraryDate?: any;
}

const HotelCardComponent: React.FC<hotelCardProps> = ({
    dataHotel,
    avatarAgent,
    refetchApi,
    roleBtnEditOnHeader,
    quoteTitle,
    isLoading,
    itineraryDate,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showEditAgentComment, setShowEditAgentComment] =
        useState<boolean>(false);
    const location = useLocation();
    const urlContainsPDFPages = location.pathname.includes('PDFPages');
    const isRole = useSelector(roleSelector);
    const externalContentId = dataHotel?.externalContentId;
    const { data: imageExternalContent } = useQueryApi(
        ['image', externalContentId],
        () => getImageContentFromExternalIdApi(externalContentId),
        {
            enabled: !!externalContentId,
        },
    );

    const hotelImage = (() => {
        const content = imageExternalContent as ImageExternalContent;
        if (
            content?.fields?.cldImages &&
            Array.isArray(content.fields.cldImages) &&
            content.fields.cldImages.length > 0
        ) {
            return content.fields.cldImages[0].url;
        }
        if (
            content?.fields?.images &&
            Array.isArray(content.fields.images) &&
            content.fields.images.length > 0
        ) {
            return content.fields.images[0].url;
        }
        return '';
    })();

    const contentHotel = (imageExternalContent as { fields: any })?.fields
        ?.description;

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const showComponentEditAgentComment = (valueChild: boolean = false) => {
        setShowEditAgentComment(valueChild);
    };

    const hotelName = dataHotel?.name || '';
    const hotelAddress = dataHotel?.address || '';
    const hotelStartDate = dataHotel?.startDate || '';
    const hotelRoomType = dataHotel?.roomType || null;
    const agentComments = dataHotel?.agentComments || [];
    const itemId = dataHotel?.id;
    const imageHotel = dataHotel?.image || '';
    const checkPDF = urlContainsPDFPages ? 400 : 600;
    const modifyURLImage = modifyURL(hotelImage, checkPDF);

    return (
        <HotelCardStyle mode={isRole} className="container">
            <div className="desktopView">
                <div>
                    <div className="hotelAndGoflView">
                        <div className="viewInforHotel">
                            <img
                                alt=""
                                className="imgHotelOrGofl"
                                src={
                                    modifyURLImage
                                        ? modifyURLImage
                                        : images.keyIndor
                                }
                            />
                            <div className="bodyAccom">
                                <div className="titleItem">
                                    Stay at {hotelName}{' '}
                                </div>
                                <div className="addressHotelOrGolf">
                                    {hotelAddress?.county}
                                </div>
                                <div className="contentInfor">
                                    {dataHotel?.duration} Night
                                    {Number(dataHotel?.duration) !== 1 &&
                                        's'}{' '}
                                    on{' '}
                                    {moment(hotelStartDate).format(
                                        'ddd Do MMMM YYYY',
                                    )}
                                </div>
                                {hotelRoomType?.map((item: any, index: any) => {
                                    const extra = item?.extra || [];
                                    return (
                                        <div
                                            className="discriptHotel"
                                            key={index}
                                        >
                                            <FontAwesomeIcon
                                                className="iconDetail"
                                                icon={faBed}
                                            />{' '}
                                            {item?.numOfRooms} x{' '}
                                            {item?.nameRoomType} for{' '}
                                            {item?.numOfPassenger}{' '}
                                            {item?.numOfPassenger > 1
                                                ? 'guests'
                                                : 'guest'}{' '}
                                            {item?.singleOccupancy &&
                                                '(Single Occupancy)'}
                                            {item?.boardBasis &&
                                            item?.boardBasis !==
                                                item?.extra[0]?.nbrCode
                                                ? `, ${item?.boardBasis}`
                                                : ''}
                                            {extra?.map(
                                                (
                                                    extraItem: any,
                                                    index: any,
                                                ) => {
                                                    return (
                                                        <>
                                                            {extraItem?.nbrCode
                                                                ? `, ${extraItem?.nbrCode}`
                                                                : ''}
                                                            {extraItem
                                                                ?.detail[0]
                                                                ?.text
                                                                ? `, ${extraItem?.detail[0]?.text}`
                                                                : ''}
                                                        </>
                                                    );
                                                },
                                            )}{' '}
                                            {`${
                                                item?.extra?.length > 0
                                                    ? 'x'
                                                    : ''
                                            }`}{' '}
                                            {`${
                                                item?.extra?.length > 0
                                                    ? item?.extra[0]?.quantity
                                                    : ''
                                            }`}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="iconAndBtnItem">
                            <img
                                alt=""
                                className="iconHotelorGolf"
                                src={icons.hotelIcon}
                            />
                            {!urlContainsPDFPages && (
                                <>
                                    {hotelImage && contentHotel && (
                                        <div
                                            className="btnSeeDetail"
                                            onClick={() => showModal()}
                                        >
                                            See details
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        className="iconAddComment"
                        onClick={() =>
                            showComponentEditAgentComment(!showEditAgentComment)
                        }
                    >
                        <img
                            alt=""
                            className="iconAdd"
                            src={icons.addComment}
                        />
                    </div>
                </div>
                <div>
                    {(agentComments?.length > 0 || showEditAgentComment) &&
                        agentComments?.map((item: any, index: any) => {
                            return (
                                <AgentCommentComponent
                                    key={index}
                                    showEdit={showEditAgentComment}
                                    itemId={itemId}
                                    typeOfComent="Accommodation"
                                    dataAgentComment={item}
                                    avatarAgent={avatarAgent}
                                    changeShowEdit={() =>
                                        showComponentEditAgentComment(
                                            !showEditAgentComment,
                                        )
                                    }
                                    refetch={refetchApi}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isLoading}
                                    itineraryDate={itineraryDate}
                                />
                            );
                        })}
                    {agentComments?.length === 0 && showEditAgentComment && (
                        <AgentCommentComponent
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="Accommodation"
                            dataAgentComment={null}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isLoading={isLoading}
                            itineraryDate={itineraryDate}
                        />
                    )}
                </div>
            </div>
            <div className="mobileView">
                <div className="hotelAndGoflViewMobile">
                    <img
                        alt=""
                        className="imgHotelOrGofl"
                        src={imageHotel ? modifyURLImage : images.keyIndor}
                    />
                    <div className="bodyViewMobileCard">
                        <div className="headerMobile">
                            <div className="titleItem">
                                Stay at {hotelName}{' '}
                            </div>
                            <img
                                alt=""
                                className="iconHotelorGolf"
                                src={icons.hotelIcon}
                            />
                        </div>
                        <div className="addressHotelOrGolf">
                            {hotelAddress?.county}
                        </div>
                        <div className="contentInfor">
                            {dataHotel?.duration} Night
                            {Number(dataHotel?.duration) !== 1 && 's'} on{' '}
                            {moment(hotelStartDate).format('ddd Do MMMM YYYY')}
                        </div>
                        {hotelRoomType?.map((item: any, index: any) => {
                            const extra = item?.extra || [];
                            return (
                                <div
                                    className="discriptHotel mobileDiscription"
                                    key={index}
                                >
                                    <FontAwesomeIcon
                                        className="iconDetail"
                                        icon={faBed}
                                    />{' '}
                                    <div className="contentItemMobile">
                                        {item?.numOfRooms} x{' '}
                                        {item?.nameRoomType} for{' '}
                                        {item?.numOfPassenger}{' '}
                                        {item?.numOfPassenger > 1
                                            ? 'guests'
                                            : 'guest'}{' '}
                                        {item?.singleOccupancy &&
                                            '(Single Occupancy)'}
                                        {item?.boardBasis &&
                                        item?.boardBasis !==
                                            item?.extra[0]?.nbrCode
                                            ? `, ${item?.boardBasis}`
                                            : ''}
                                        {extra?.map(
                                            (extraItem: any, index: any) => {
                                                return (
                                                    <>
                                                        {extraItem?.nbrCode
                                                            ? `, ${extraItem?.nbrCode}`
                                                            : ''}
                                                        {extraItem?.detail[0]
                                                            ?.text
                                                            ? `, ${extraItem?.detail[0]?.text}`
                                                            : ''}
                                                    </>
                                                );
                                            },
                                        )}{' '}
                                        {`${
                                            item?.extra?.length > 0 ? 'x' : ''
                                        }`}{' '}
                                        {`${
                                            item?.extra?.length > 0
                                                ? item?.extra[0]?.quantity
                                                : ''
                                        }`}
                                    </div>
                                </div>
                            );
                        })}
                        {!urlContainsPDFPages && (
                            <>
                                {hotelImage && contentHotel && (
                                    <div
                                        className="btnSeeDetail"
                                        onClick={() => showModal()}
                                    >
                                        See details
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div>
                    {(agentComments?.length > 0 || showEditAgentComment) &&
                        agentComments?.map((item: any, index: any) => {
                            return (
                                <AgentCommentComponent
                                    key={index}
                                    showEdit={showEditAgentComment}
                                    itemId={itemId}
                                    typeOfComent="Accommodation"
                                    dataAgentComment={item}
                                    avatarAgent={avatarAgent}
                                    changeShowEdit={() =>
                                        showComponentEditAgentComment(
                                            !showEditAgentComment,
                                        )
                                    }
                                    refetch={refetchApi}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    itineraryDate={itineraryDate}
                                />
                            );
                        })}
                    {agentComments?.length === 0 && showEditAgentComment && (
                        <AgentCommentComponent
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="Accommodation"
                            dataAgentComment={null}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            itineraryDate={itineraryDate}
                        />
                    )}
                </div>
            </div>
            <ModalComponent
                width={525}
                visible={isModalVisible}
                onClose={handleClose}
                title={`Stay at ${hotelName}`}
                content={
                    <ModalTouristDetination
                        image={hotelImage}
                        discription={contentHotel}
                    />
                }
            />
        </HotelCardStyle>
    );
};

export default HotelCardComponent;
