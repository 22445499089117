import { faTaxi, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import TransferCardStyle from './style';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import icons from 'src/assets/icon';
import AgentCommentComponent from '../agentComment';
interface transferCardProps {
    dataTransfer?: any;
    avatarAgent?: any;
    refetchApi?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isLoading?: boolean;
    itineraryDate?: any;
}

const TransferCard: React.FC<transferCardProps> = ({
    dataTransfer,
    avatarAgent,
    refetchApi,
    roleBtnEditOnHeader,
    quoteTitle,
    isLoading,
    itineraryDate,
}) => {
    const isRole = useSelector(roleSelector);
    const [showEditAgentComment, setShowEditAgentComment] =
        useState<boolean>(false);

    const showComponentEditAgentComment = (valueChild: boolean = false) => {
        setShowEditAgentComment(valueChild);
    };
    const nametransfer = dataTransfer?.carType || '';
    const numberOfPassenger = dataTransfer?.passenger || 0;
    const pickUp = dataTransfer?.pickUp || '';
    const agentComments = dataTransfer?.agentComments || [];
    const itemId = dataTransfer?.id;
    const pickUpTime = pickUp?.time === '00:00' ? 'time TBC' : pickUp?.time;

    return (
        <TransferCardStyle mode={isRole}>
            <div>
                <div className="traditionView">
                    <div className="viewDetailPlanFlight">
                        <div className="headerCard">
                            <div className="titleFightPlan">{nametransfer}</div>
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faTaxi}
                            />
                        </div>
                        <div>
                            <div className="detailPlanflight">
                                <FontAwesomeIcon
                                    className="iconTransportationDisscript"
                                    icon={faTaxi}
                                />
                                <div>
                                    <div className="locationName">
                                        {`${pickUp?.location} To
                                    ${pickUp?.to} at ${pickUpTime}`}
                                    </div>
                                    <div className="textTimeFont">
                                        {numberOfPassenger} Passenger
                                        {Number(numberOfPassenger) > 1
                                            ? 's'
                                            : ''}{' '}
                                        <FontAwesomeIcon
                                            className="iconPassenger"
                                            icon={faUserGroup}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="iconAddComment"
                    onClick={() =>
                        showComponentEditAgentComment(!showEditAgentComment)
                    }
                >
                    <img alt="" className="iconAdd" src={icons.addComment} />
                </div>
            </div>
            {(agentComments?.length > 0 || showEditAgentComment) &&
                agentComments?.map((item: any, index: any) => {
                    return (
                        <AgentCommentComponent
                            key={index}
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="Transfer"
                            dataAgentComment={item}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isLoading={isLoading}
                            itineraryDate={itineraryDate}
                        />
                    );
                })}
            {agentComments?.length === 0 && showEditAgentComment && (
                <AgentCommentComponent
                    showEdit={showEditAgentComment}
                    itemId={itemId}
                    typeOfComent="Transfer"
                    dataAgentComment={null}
                    avatarAgent={avatarAgent}
                    changeShowEdit={() =>
                        showComponentEditAgentComment(!showEditAgentComment)
                    }
                    refetch={refetchApi}
                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                    quoteTitle={quoteTitle}
                    isLoading={isLoading}
                    itineraryDate={itineraryDate}
                />
            )}
        </TransferCardStyle>
    );
};

export default TransferCard;
