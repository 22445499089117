import styled from '@emotion/styled';
interface AgentComentInPricingProps {
    mode?: any;
    addition?: any;
}

const AgentComentInPricingStyle = styled('div')<AgentComentInPricingProps>`
    page-break-inside: avoid;
    .reviewSeller {
        width: 100%;
        justify-content: space-between;
        background-color: #1d3254 !important;
        color: #ffffff !important;
    }

    @media print {
        .reviewSeller {
            width: 100%;
            justify-content: space-between;
            background-color: #1d3254 !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            color: #ffffff !important;
        }
        .reviewSellerAgentComent {
            display: flex;
            flex-direction: column;
            padding: 34px 50px 34px 30px;
            border-radius: 4px;
            background: #1d3254 !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            margin-bottom: 20px;
            box-shadow: none;
        }
        .addtionalView {
            display: flex;
            flex-direction: column;
            padding: 30px;
            border-radius: 6px;
            background: #1d3254 !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            margin-bottom: ${(props) => (props.addition ? '0px' : '20px')};
            box-shadow: none;
        }
    }
    .viewDataComment {
        width: 80%;
        word-wrap: break-word;
        text-align: center;
        color: #ffffff !important;
    }
    .viewCommentforAdditionalInfor {
        width: 100%;
        word-wrap: break-word;
        text-align: left;
        color: #ffffff !important;
    }
    .avatarSeller {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .avatar {
        width: 87px !important;
        height: 87px !important;
        background-color: #ffffff;
        border-radius: 70px;
    }
    .viewInputEdit {
        width: 80%;
    }
    .reviewSellerAgentComent {
        display: flex;
        flex-direction: column;
        padding: 34px 50px 34px 30px;
        border-radius: 4px;
        background: #1d3254 !important;
        margin-bottom: 20px;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    }
    .addtionalView {
        display: flex;
        flex-direction: column;
        padding: 30px;
        border-radius: 6px;
        background: #1d3254;
        margin-bottom: ${(props) => (props.addition ? '0px' : '20px')};
        box-shadow: ${(props) =>
            props.mode !== 0
                ? 'none'
                : '4px 4px 20px 0px rgba(156, 171, 194, 0.4)'};
    }
    .titleAdditional {
        color: #ffffff;
        font-family: 'Lato';
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 16px;
    }
    .reviewSeller {
        display: flex;
        align-items: center;
        padding: 34px 50px 34px 30px;
        /* align-items: center; */
        border-radius: 4px;
        background: #f0fffe;
        margin-bottom: 20px;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    }
    .reviewSellerInput {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;
    }
    .btnEditComment {
        display: flex;
        width: 80%;
        justify-content: space-between;
    }
    .btnEditCommentAdditional {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .btnSave {
        color: #ffffff;
        margin-right: 16px;
        font-family: 'Lato';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-decoration-line: underline;
        cursor: ${(props) => (props.mode !== 0 ? 'pointer' : 'not-allowed')};
    }
    .btnSave:hover {
        transform: scale(1.05);
    }
    .btnDelete {
        color: #ffffff;
        font-family: 'Lato';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-decoration-line: underline;
        cursor: pointer;
    }
    .btnDelete:hover {
        transform: scale(1.05);
    }
    .backgroundAvatar {
        width: 93px;
        height: 95px;
        display: flex;
        background-color: #12b897;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
    }
    .avatarReals {
        width: 87.065px;
        height: 89px;
        color: #12b897;
        object-fit: cover;
        border-radius: 45px;
    }
    .btnLeft {
        display: flex;
    }
    .areaMaxlenght {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .viewMaxLenght {
        padding: 8px 10px;
        align-items: center;
        background: rgba(18, 184, 151, 0.1);
        color: #ffffff;
        text-align: center;
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 400;
        border-top: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        border-left: 1px solid #ffffff;
    }
    .textAreaComment {
        border-radius: 0px;
        border: 1px solid #12b897;
    }
    .textAreaComment:hover {
        border-radius: 0px;
        border: 1px solid #12b897;
    }
    .avatar {
        width: 87.065px;
        height: 89px;
        color: #12b897;
        object-fit: cover;
    }
    .avatarImage {
        width: 87.065px;
        height: 89px;
        color: #12b897;
        object-fit: cover;
        border-radius: 45px;
    }
    .contentEdit {
        width: 100%;
        height: 240px;
        display: inline-flex;
        align-items: center;
        background-color: #ffffff;
    }
    @media screen and (max-width: 768px) {
        .reviewSeller {
            display: flex;
            flex-direction: column;
            padding: 20px !important;
            gap: 24px;
        }
        .viewDataComment {
            width: 100%;
            word-wrap: break-word;
            text-align: left;
            font-family: 'Lato';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.16px;
            color: #ffffff !important;
            > p {
                color: #ffffff !important;
            }
        }
        .viewCommentforAdditionalInfor {
            width: 100%;
            word-wrap: break-word;
            text-align: left;
            color: #ffffff !important;
        }
        .avatarSeller {
            width: 100% !important;
            align-items: flex-end;
            background-color: #1d3254 !important;
        }
    }
`;

export default AgentComentInPricingStyle;
