import React, { useState } from 'react';
import GolfsCardStyle from './style';
import images from 'src/assets/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faGolfBallTee } from '@fortawesome/free-solid-svg-icons';
import ModalComponent from '../modalCustom';
import ModalTouristDetination from 'src/pages/QuoteDetailPage/ItineraryInDetail/ModalTouristDestination';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import icons from 'src/assets/icon';
import AgentCommentComponent from '../agentComment';
import { useLocation } from 'react-router-dom';
import { modifyURL } from 'src/const/contain';
import useQueryApi from 'src/hooks/useApiQuery';
import { getImageContentFromExternalIdApi } from 'src/api/apiGetImageAndContentfFromExternalId';
import { ImageExternalContent } from 'src/types';

interface GolfsCardProps {
    dataGolf?: any;
    avatarAgent?: any;
    refetchApi?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTitle?: any;
    isLoading?: boolean;
    itineraryDate?: any;
}

const GolfsCardComponent: React.FC<GolfsCardProps> = ({
    dataGolf,
    avatarAgent,
    refetchApi,
    roleBtnEditOnHeader,
    quoteTitle,
    isLoading,
    itineraryDate,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const isRole = useSelector(roleSelector);
    const [showEditAgentComment, setShowEditAgentComment] =
        useState<boolean>(false);
    const location = useLocation();
    const urlContainsPDFPages = location.pathname.includes('PDFPages');
    const showModal = () => {
        setIsModalVisible(true);
    };

    
    const externalContentId = dataGolf?.externalContentId;
    const { data: imageExternalContent } = useQueryApi(
        ['image', externalContentId],
        () => getImageContentFromExternalIdApi(externalContentId),
        {
            enabled: !!externalContentId,
        },
    );



    const golfImage = (() => {
        const content = imageExternalContent as ImageExternalContent;
        if (content?.fields?.cldImages && Array.isArray(content.fields.cldImages) && content.fields.cldImages.length > 0) {
            return content.fields.cldImages[0].url;
        }
        if (content?.fields?.images && Array.isArray(content.fields.images) && content.fields.images.length > 0) {
            return content.fields.images[0].url;
        }
        return '';
    })();
    


    const contentGolf = (imageExternalContent as { fields: any })?.fields
        ?.description;

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const showComponentEditAgentComment = (valueChild: boolean = false) => {
        setShowEditAgentComment(valueChild);
    };

    const nameOfGolf = dataGolf?.serviceDescription;
    const numRound = dataGolf?.rounds || '';
    const detailRound = dataGolf?.detailRound || [];
    const totalPassengers = detailRound.reduce(
        (total: any, round: any) => total + Number(round.numOfPassenger),
        0,
    );
    const buggies = dataGolf?.buggies || [];
    const image = dataGolf?.image || '';
    const agentComments = dataGolf?.agentComments || [];
    const itemId = dataGolf?.id;
    const serviceDescription = dataGolf?.serviceDescription;
    const checkPDF = urlContainsPDFPages?400:600
    const modifyURLImage = modifyURL(golfImage,checkPDF);

    return (
        <GolfsCardStyle mode={isRole}>
            <div className="desktopView">
                <div>
                    <div className="hotelAndGoflView">
                        <div className="viewInforGolf">
                            <img
                                alt=""
                                className="imgHotelOrGofl"
                                src={modifyURLImage ? modifyURLImage : images.golfDefault}
                            />
                            <div className="bodyAccom">
                                <div className="titleItem">
                                Golf at {nameOfGolf}{' '}
                                </div>
                                <div className="contentInfor">
                                    {numRound} Round
                                    {Number(numRound) > 1 ? 's' : ''} at{' '}
                                    {serviceDescription}{' '}
                                    {dataGolf?.documentDescription} for{' '}
                                    {totalPassengers} golfer
                                    {Number(totalPassengers) > 1 ? 's' : ''}
                                </div>
                                <div className="viewItemDescription">
                                    {detailRound?.map(
                                        (item: any, index: any) => {
                                            return (
                                                <div
                                                    className="timeAndExtraRoundOfGolf"
                                                    key={index}
                                                >
                                                    <div>
                                                        <FontAwesomeIcon
                                                            className="iconDetail"
                                                            icon={faFlag}
                                                        />
                                                    </div>
                                                    <div className="timeOfRound extrabuggy">
                                                        {moment
                                                            .utc(item?.time)
                                                            .format('HH:mm') ===
                                                        '00:00'
                                                            ? 'Time TBC'
                                                            : moment
                                                                  .utc(
                                                                      item?.time,
                                                                  )
                                                                  .format(
                                                                      'HH:mm',
                                                                  )}{' '}
                                                        on{' '}
                                                        {moment
                                                            .utc(item?.time)
                                                            .format(
                                                                'DD/MM/YYYY',
                                                            )}
                                                    </div>
                                                    <div className="detailRoundGolf">
                                                        {' '}
                                                        x {
                                                            item?.numOfPassenger
                                                        }{' '}
                                                        {Number(
                                                            item?.numOfPassenger,
                                                        ) > 1
                                                            ? 'golfers'
                                                            : 'golfer'}
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                                    {buggies?.length > 0 ? (
                                        <>
                                            {buggies?.map(
                                                (item: any, index: any) => {
                                                    return (
                                                        <div
                                                            className="timeAndExtraRoundOfGolf"
                                                            key={index}
                                                        >
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    className="iconDetail"
                                                                    icon={
                                                                        faGolfBallTee
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="timeOfRound extrabuggy">
                                                                {item?.documentDescription
                                                                    ? item?.documentDescription
                                                                    : item?.description}
                                                            </div>
                                                            <div className="detailRoundGolf">
                                                                x{' '}
                                                                {item?.quantity}
                                                            </div>
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="iconAndBtnItem">
                            <FontAwesomeIcon
                                className="iconHotelorGolf"
                                icon={faGolfBallTee}
                            />
                            {!urlContainsPDFPages && (
                                <>
                                    {golfImage && golfImage && (
                                        <div
                                            className="btnSeeDetail"
                                            onClick={() => showModal()}
                                        >
                                            See details
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        className="iconAddComment"
                        onClick={() =>
                            showComponentEditAgentComment(!showEditAgentComment)
                        }
                    >
                        <img
                            alt=""
                            className="iconAdd"
                            src={icons.addComment}
                        />
                    </div>
                </div>
                <div>
                    {(agentComments?.length > 0 || showEditAgentComment) &&
                        agentComments?.map((item: any, index: any) => {
                            return (
                                <AgentCommentComponent
                                    key={index}
                                    showEdit={showEditAgentComment}
                                    itemId={itemId}
                                    typeOfComent="Golf"
                                    dataAgentComment={item}
                                    avatarAgent={avatarAgent}
                                    changeShowEdit={() =>
                                        showComponentEditAgentComment(
                                            !showEditAgentComment,
                                        )
                                    }
                                    refetch={refetchApi}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isLoading}
                                    itineraryDate={itineraryDate}
                                />
                            );
                        })}
                    {agentComments?.length === 0 && showEditAgentComment && (
                        <AgentCommentComponent
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="Golf"
                            dataAgentComment={null}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isLoading={isLoading}
                            itineraryDate={itineraryDate}
                        />
                    )}
                </div>
            </div>
            <div className="mobileView">
                <div className="hotelAndGoflViewMobile">
                    <img
                        alt=""
                        className="imgHotelOrGofl"
                        src={image ? modifyURLImage : images.golfDefault}
                    />
                    <div className="bodyViewMobileCard">
                        <div className="headerMobile">
                            <div className="titleItem">
                                Golf at {nameOfGolf}{' '}
                            </div>
                            <FontAwesomeIcon
                                className="iconHotelorGolf"
                                icon={faGolfBallTee}
                            />
                        </div>
                        <div className="contentInfor">
                            {numRound} Round at {nameOfGolf} for{' '}
                            {totalPassengers} golfers
                        </div>
                        {detailRound?.map((item: any, index: any) => {
                            return (
                                <div
                                    className="discriptRoundOfGofl"
                                    key={index}
                                >
                                    <div>
                                        <FontAwesomeIcon
                                            className="iconDetail"
                                            icon={faFlag}
                                        />
                                    </div>
                                    <div className="timeOfRound">
                                        {moment
                                            .utc(item?.time)
                                            .format('HH:mm') === '00:00'
                                            ? 'TBC'
                                            : moment
                                                  .utc(item?.time)
                                                  .format('HH:mm')}{' '}
                                        on{' '}
                                        {moment
                                            .utc(item?.time)
                                            .format('DD/MM/YYYY')}
                                    </div>
                                    <div className="detailRoundGolf">
                                        {' '}
                                        x {item?.numOfPassenger}{' '}
                                        {Number(item?.numOfPassenger) > 1
                                            ? 'golfers'
                                            : 'golfer'}
                                    </div>
                                </div>
                            );
                        })}
                        {buggies?.length > 0 ? (
                            <>
                                {buggies?.map((item: any, index: any) => {
                                    return (
                                        <div
                                            className="discriptRoundOfGofl"
                                            key={index}
                                        >
                                            <div>
                                                <FontAwesomeIcon
                                                    className="iconDetail"
                                                    icon={faGolfBallTee}
                                                />
                                            </div>
                                            <div className="timeOfRound extrabuggy">
                                                {item?.documentDescription
                                                    ? item?.documentDescription
                                                    : item?.description}
                                            </div>
                                            <div className="detailRoundGolf">
                                                x {item?.quantity}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : null}
                        {!urlContainsPDFPages && (
                            <>
                                {golfImage && contentGolf && (
                                    <div
                                        className="btnSeeDetail"
                                        onClick={() => showModal()}
                                    >
                                        See details
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div>
                    {(agentComments?.length > 0 || showEditAgentComment) &&
                        agentComments?.map((item: any, index: any) => {
                            return (
                                <AgentCommentComponent
                                    key={index}
                                    showEdit={showEditAgentComment}
                                    itemId={itemId}
                                    typeOfComent="Golf"
                                    dataAgentComment={item}
                                    avatarAgent={avatarAgent}
                                    changeShowEdit={() =>
                                        showComponentEditAgentComment(
                                            !showEditAgentComment,
                                        )
                                    }
                                    refetch={refetchApi}
                                    roleBtnEditOnHeader={roleBtnEditOnHeader}
                                    quoteTitle={quoteTitle}
                                    isLoading={isLoading}
                                    itineraryDate={itineraryDate}
                                />
                            );
                        })}
                    {agentComments?.length === 0 && showEditAgentComment && (
                        <AgentCommentComponent
                            showEdit={showEditAgentComment}
                            itemId={itemId}
                            typeOfComent="Golf"
                            dataAgentComment={null}
                            avatarAgent={avatarAgent}
                            changeShowEdit={() =>
                                showComponentEditAgentComment(
                                    !showEditAgentComment,
                                )
                            }
                            refetch={refetchApi}
                            roleBtnEditOnHeader={roleBtnEditOnHeader}
                            quoteTitle={quoteTitle}
                            isLoading={isLoading}
                            itineraryDate={itineraryDate}
                        />
                    )}
                </div>
            </div>
            <ModalComponent
                width={525}
                visible={isModalVisible}
                onClose={handleClose}
                title={`Golf at ${nameOfGolf}`}
                content={
                    <ModalTouristDetination
                        image={golfImage}
                        discription={contentGolf}
                    />
                }
            />
        </GolfsCardStyle>
    );
};

export default GolfsCardComponent;
