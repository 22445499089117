import React, { useEffect, useRef, useState } from 'react';
import QuotePDFPageStyle from './style';
import { useSelector } from 'react-redux';
import { nameView } from 'src/const/enum';
import { useLocation } from 'react-router-dom';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import images from 'src/assets/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency } from 'src/const/contain';
import QuoteTitleAndInputUpdateComponent from 'src/components/viewQuoteTitle';
import QouteNavigateCompoment from '../QuoteNavigate';
import IntroductionGoflTravel from 'src/pages/QuoteDetailPage/IntroductionGolfTravel';
import { getDetailQuote } from 'src/api/apiGetDetailQuote';
import { getQuoteDetailJson } from 'src/api/apiGetQuoteDetailJson';
import { getImageApi } from 'src/api/apiGetImage';
import ItineraryInDetailComponent from '../ItineraryInDetail';
import PricingAndBookingComponent from '../PricingAndBooking';
import ImportantInformationComponent from '../ImportantInformation';
import TermsAndCoditionsComponent from '../TermsAndCoditions';
import useQueryApi from 'src/hooks/useApiQuery';
import { getQuoteDetailVersion } from 'src/api/apiGetQuoteDetailVersion';

interface destopProps {
    Imange?: any;
    dataContenFull?: any;
    dataJsonChild?: any;
    onUpdate?: () => void;
    intinerary?: any;
}

export const PdfPagesComponent: React.FC<destopProps> = ({
    Imange,
    dataContenFull,
    dataJsonChild,
    onUpdate,
    intinerary,
}) => {
    const [data, setData] = useState<any>();
    const [mainImage, setMainImage] = useState<any>('');
    const [childData, setChildData] = useState<any>({
        id: nameView.GOFL_TRAVEL_EXPERT,
        content: 'Introduction from your Golf Travel Expert',
    });
    const [isDataReady, setIsDataReady] = useState(false);
    const [dataJson, setDataJson] = useState<any>();
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isRole = useSelector(roleSelector);
    const id = queryParams.get('id') || '';
    const type = queryParams.get('type');
    const contentRef = useRef<HTMLDivElement>(null);

    const { data: dataVersion } = useQueryApi(
        ['id', id],
        () => getQuoteDetailVersion(id),
        {
            enabled: !!id && !type,
        },
    );

    const dataVer = (dataVersion as { data: any })?.data;
    const contenfullDataVer = dataVer?.contentful || null;
    const introductionDataVer = dataVer?.introduction || null;
    const defaultImageIdVer = dataVer?.contentful?.defaultImage?.sys?.id;
    const atAglance = dataVer?.quoteItinerary;
    const resConsultantVersion = dataVer?.introduction?.resConsultant

    const { data: dataImageVer } = useQueryApi(
        ['defaultImageIdVer', defaultImageIdVer],
        () => getImageApi(defaultImageIdVer),
        {
            enabled: !!defaultImageIdVer,
        },
    );
    const dataImageVerParserString =
        `http:${(dataImageVer as { fields: any })?.fields?.file?.url}` || '';

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchData = async () => {
        try {
            const [response, responseDataJson] = await Promise.all([
                getDetailQuote(),
                id ? getQuoteDetailJson(id) : Promise.resolve(null),
            ]);
            if (responseDataJson) {
                setDataJson(responseDataJson);
            } else {
                setDataJson(null);
            }
            setData(response?.fields);

            const defaultImageId = response?.fields?.defaultImage?.sys?.id;
            if (defaultImageId) {
                try {
                    const imageResponse = await getImageApi(defaultImageId);
                    setMainImage(imageResponse);
                } catch (err) {
                    setMainImage(null);
                }
            }
            setIsDataReady(true);
        } catch (err) {
            setData(null);
            setIsDataReady(true);
        }
    };

    useEffect(() => {
        if (type) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const imageIntroDuction = `http:${mainImage?.fields?.file?.url}`;
    const resConsultant = dataJson?.resConsultant;
    const quoteTitle = dataJson?.title;
    const roundedTotalDiscount = Math.round(
        Math.abs(Number(dataJson?.price?.totalDiscount || 0)),
    );
    return (
        <div>
            <QuotePDFPageStyle>
                <div ref={contentRef} className="deskTopView">
                    <div className="headerPage">
                        <div className="logoHeader">
                            <img
                                alt=""
                                className="LogoIMG"
                                src={images.logoYGT}
                            />
                        </div>
                        {roundedTotalDiscount !== 0 ? (
                            <div className="viewPromotion">
                                <div className="contentBtnPromotion">
                                    <FontAwesomeIcon
                                        icon={faCoins}
                                        className="iconCoins"
                                    />
                                    <p>
                                        You’ve saved on your trip today with a £
                                        {formatCurrency(roundedTotalDiscount)}{' '}
                                        discount
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="headerTitle">
                        <div className="titleHeader ">
                            <QuoteTitleAndInputUpdateComponent
                                showRole={isRole}
                                quoteTitle={quoteTitle}
                            />
                        </div>
                        <div className="titleview">{childData.content}</div>
                    </div>
                    <div className="viewShowInfor">
                        <div className="viewInforLeft">
                            <div>
                                <IntroductionGoflTravel
                                    customProp={type ? data : contenfullDataVer}
                                    dataJsonProp={
                                        type ? dataJson : introductionDataVer
                                    }
                                    image={
                                        type
                                            ? imageIntroDuction
                                            : dataImageVerParserString
                                    }
                                />
                            </div>
                            <div className="viewPDF intineraryView">
                                <ItineraryInDetailComponent
                                    customProp={type ? data : contenfullDataVer}
                                    dataJsonProp={
                                        type
                                            ? dataJson
                                            : introductionDataVer
                                    }
                                    image={
                                        type
                                            ? imageIntroDuction
                                            : dataImageVerParserString
                                    }
                                />
                            </div>
                            <div className="viewPDF">
                                <PricingAndBookingComponent
                                    dataReadyToBook={
                                        type ? data : contenfullDataVer
                                    }
                                    dataJsonProp={
                                        type
                                            ? dataJson
                                            : introductionDataVer
                                    }
                                    customProp={data ? data : contenfullDataVer}
                                    image={
                                        type
                                            ? imageIntroDuction
                                            : dataImageVerParserString
                                    }
                                />
                            </div>
                            <div className="viewPDF">
                                <ImportantInformationComponent
                                    dataImportanInfor={
                                        type ? data : contenfullDataVer
                                    }
                                    dataJsonProp={
                                        type
                                            ? dataJson
                                            : introductionDataVer
                                    }
                                    image={
                                        type
                                            ? imageIntroDuction
                                            : dataImageVerParserString
                                    }
                                />
                            </div>
                            <div className="viewPDF">
                                <TermsAndCoditionsComponent
                                    customProp={type ? data : contenfullDataVer}
                                    dataJsonProp={
                                        type
                                            ? dataJson
                                            : introductionDataVer
                                    }
                                    image={
                                        type
                                            ? imageIntroDuction
                                            : dataImageVerParserString
                                    }
                                />
                            </div>
                        </div>
                        <div className="viewInforRight">
                            <QouteNavigateCompoment
                                dataJsonResConsultant={type?resConsultant:resConsultantVersion}
                                groupData={
                                    type ? dataJson : introductionDataVer
                                }
                                intinerary={atAglance}
                                showNavigate={false}
                                showGroup={true}
                            />
                        </div>
                    </div>
                </div>
            </QuotePDFPageStyle>
        </div>
    );
};
