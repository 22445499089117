import React from 'react';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { formatCurrency } from 'src/const/contain';

interface ReadyToBookProps {
    dataReadyToBookComponent?: any;
    toTalPrice?: any;
    toTalDeposit?: any;
    balenceDueDay?: any;
    deposit2?: any;
    deposit3?: any;
    checkEvent?: any;
}

const ReadyToBookComponent: React.FC<ReadyToBookProps> = ({
    dataReadyToBookComponent,
    toTalPrice,
    toTalDeposit,
    balenceDueDay,
    deposit2,
    deposit3,
    checkEvent,
}) => {
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idQuote = queryParams.get('id');
    const type = queryParams.get('type');
    const urlContainsPDFPages = location.pathname.includes('PDFPages');
    const idIntro = localStorage.getItem('idIntro');


    const replacements: { [key: string]: string } = {
        '{Deposit}': `£${formatCurrency(toTalDeposit)}`,
        '{BalanceDueDate}': `${balenceDueDay}`,
        '{QuoteTotalPrice}': `£${formatCurrency(toTalPrice)}`,
        '{DepositAmount2}': `£${formatCurrency(deposit2?.amount)}`,
        '{DepositDue2}': `${deposit2?.dueDate}`,
    };

    const readyToBookEvent =
        dataReadyToBookComponent?.readyToBookEvent?.content[0]?.content
            .map((item: any) => item.value)
            .join('');
    let formattextReadyToBookEvent = readyToBookEvent?.replace(/\n/g, '<br/>');
    Object.keys(replacements).forEach((key) => {
        const regex = new RegExp(key, 'g');
        formattextReadyToBookEvent = formattextReadyToBookEvent?.replace(
            regex,
            replacements[key],
        );
    });

    const readyToBookEvent1 =
        dataReadyToBookComponent?.readyToBookEvent?.content[1]?.content
            .map((item: any) => item.value)
            .join('');
    let formattextReadyToBookEvent1 = readyToBookEvent1?.replace(
        /\n/g,
        '<br/>',
    );
    Object.keys(replacements).forEach((key) => {
        const regex = new RegExp(key, 'g');
        formattextReadyToBookEvent1 = formattextReadyToBookEvent1?.replace(
            regex,
            replacements[key],
        );
    });

    const readyToBookStandard =
        dataReadyToBookComponent?.readyToBookStandard?.content[0]?.content
            .map((item: any) => item.value)
            .join('');
    let formattextReadyToBookStandard = readyToBookStandard?.replace(
        /\n/g,
        '<br/>',
    );
    Object.keys(replacements).forEach((key) => {
        const regex = new RegExp(key, 'g');
        formattextReadyToBookStandard = formattextReadyToBookStandard?.replace(
            regex,
            replacements[key],
        );
    });

    const readyToBookStandard1 =
        dataReadyToBookComponent?.readyToBookStandard?.content[1]?.content
            .map((item: any) => item.value)
            .join('');
    let formattextReadyToBookStandard1 = readyToBookStandard1?.replace(
        /\n/g,
        '<br/>',
    );
    Object.keys(replacements).forEach((key) => {
        const regex = new RegExp(key, 'g');
        formattextReadyToBookStandard1 =
            formattextReadyToBookStandard1?.replace(regex, replacements[key]);
    });

    const readyToBookStandard2 =
        dataReadyToBookComponent?.readyToBookStandard?.content[2]?.content
            .map((item: any) => item.value)
            .join('');
    let formattextReadyToBookStandard2 = readyToBookStandard2?.replace(
        /\n/g,
        '<br/>',
    );
    Object.keys(replacements).forEach((key) => {
        const regex = new RegExp(key, 'g');
        formattextReadyToBookStandard2 =
            formattextReadyToBookStandard2?.replace(regex, replacements[key]);
    });

    const eventRedirectToPay = () => {
        window.open(
            `${process.env.REACT_APP_URL_PAYMENT}/quotes/QV${type?idQuote:idIntro}`,
            '_blank',
        );
    };

    const showPriceInBtnSecureQuote = (toTalPrice: any, toTalDeposit: any) => {
        if (Number(toTalPrice) === Number(toTalDeposit)) {
            return formatCurrency(toTalPrice);
        } else if (Number(toTalPrice) !== Number(toTalDeposit)) {
            return formatCurrency(toTalDeposit);
        }
    };

    return (
        <div>
            <div className="titleAreaChoosen">Ready to book?</div>
            <div className="contentAreaChoosen">
                {(checkEvent === true || type === 'Event') &&
                    parse(
                        Number(toTalPrice) === Number(toTalDeposit)
                            ? formattextReadyToBookEvent1
                            : formattextReadyToBookEvent,
                    )}
                {(checkEvent === false || type === 'Standard') &&
                    parse(
                        Number(toTalPrice) === Number(toTalDeposit)
                            ? formattextReadyToBookStandard
                            : Number(toTalPrice) !== Number(toTalDeposit) &&
                              Number(deposit2?.amount) !== 0
                            ? parse(formattextReadyToBookStandard2)
                            : parse(formattextReadyToBookStandard1),
                    )}
            </div>
            {!urlContainsPDFPages ? (
                <div className="btnEnd" onClick={eventRedirectToPay}>
                    Secure Quote Now for £
                    {showPriceInBtnSecureQuote(toTalPrice, toTalDeposit)}
                </div>
            ) : (
                <div className="btnEnd" onClick={eventRedirectToPay}>
                    <a
                        className="btnLink"
                        href={`${process.env.REACT_APP_URL_PAYMENT}/quotes/QV${type?idQuote:idIntro}`}
                    >
                        Secure Quote Now for £
                        {showPriceInBtnSecureQuote(toTalPrice, toTalDeposit)}
                    </a>
                </div>
            )}
        </div>
    );
};

export default ReadyToBookComponent;
