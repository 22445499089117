import React, { useState } from 'react';
import ModalEditCommentStyle from './style';
import { Button, Spin } from 'antd';
import { useMutationApi } from 'src/hooks/useApiMutation';
import { apiCreateAgentComment } from 'src/api/apiCreateAgentComment';
import { toast } from 'react-toastify';
import { apiUpdateAgentComment } from 'src/api/apiUpdateAgentComment';
import { apiDeleteAgentComment } from 'src/api/apiDeleteAgentComent';

interface ModalTourDestination {
    quoteTitle?: string;
    idItem?: any;
    itemType?: any;
    commentValue?: any;
    idComment?: any;
    optionEvent?: any;
    itineraryDate?:any;
    close?: () => void;
    changeShowEdit?: () => void;
    refetch?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    isloading?: () => void;
}
const ModalEditComment: React.FC<ModalTourDestination> = ({
    quoteTitle,
    idItem,
    itemType,
    commentValue,
    idComment,
    optionEvent,
    itineraryDate,
    close,
    changeShowEdit,
    refetch,
    isloading,
}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const idItinerary = queryParams.get('id') || 0;
    const [isMounted, setIsMounted] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);

    const { mutate } = useMutationApi<{ id: number | string; param: any }, any>(
        ({ id, param }) => apiCreateAgentComment(id, param),
        {
            onSuccess: (response) => {
                toast.success('Your comment has been created.');
                refetch?.();
                changeShowEdit?.();
                close?.();
                setLoading(false);
                isloading?.();
            },
            onError: (error) => {
                toast.error('Failed to create your comment.');
                close?.();
                setLoading(false);
            },
        },
    );

    const { mutate: updateMutation } = useMutationApi<
        { id: number | string; idItem: any; param: any },
        any
    >(({ id, idItem, param }) => apiUpdateAgentComment(id, idItem, param), {
        onSuccess: (response) => {
            toast.success('Your comment has been updated.');
            refetch?.();
            changeShowEdit?.();
            close?.();
            setLoading(false);
            isloading?.();
        },
        onError: (error) => {
            toast.error('Failed to update your comment.');
            close?.();
            setLoading(false);
        },
    });

    const { mutate: deleteMutation } = useMutationApi<
        { id: number | string; idItem: any },
        any
    >(({ id, idItem }) => apiDeleteAgentComment(id, idItem), {
        onSuccess: (response) => {
            toast.success('Your comment has been deleted.');
            refetch?.();
            changeShowEdit?.();
            close?.();
            setLoading(false);
            isloading?.();
        },
        onError: (error) => {
            toast.error('Failed to delete your comment.');
            close?.();
            setLoading(false);
        },
    });

    const paramCreateComment = {
        itemType: itemType,
        itemId: idItem,
        itineraryDate: itineraryDate,
        comment: commentValue,
    };

    const paramEdit = {
        comment: commentValue,
    };

    const createComment = () => {
        mutate({ id: Number(idItinerary), param: paramCreateComment });
        setLoading(true);
    };

    const eventUpdateQuote = () => {
        updateMutation({
            id: Number(idItinerary),
            idItem: idComment,
            param: paramEdit,
        });
        setLoading(true);
    };

    const deleteComment = () => {
        deleteMutation({ id: Number(idItinerary), idItem: idComment });
        setLoading(true);
    };

    const resetAndUnmount = () => {
        setIsMounted(false); 
        setTimeout(() => {
            setIsMounted(true); 
            close?.(); 
        }, 0); 
    };

    if (!isMounted) {
        return null; 
    }

    return (
        <ModalEditCommentStyle>
            <Spin spinning={loading} delay={500}>
                <div>
                    <div className="contentModal">
                        Are you sure to{' '}
                        {optionEvent === 'Delete' ? 'delete' : 'save'} this
                        comment for the quote{' '}
                        <span className="quoteTitle">{quoteTitle}</span>
                    </div>
                    <div className="groupBtn">
                        <Button
                            type="primary"
                            className="btnEdit"
                            onClick={resetAndUnmount}
                            ghost
                        >
                            {optionEvent === 'Delete'
                                ? 'Cancel'
                                : 'Back to Edit'}
                        </Button>
                        {optionEvent === 'Create' && (
                            <Button
                                type="primary"
                                className="btnSend"
                                onClick={createComment}
                            >
                                Save
                            </Button>
                        )}
                        {optionEvent === 'Edit' && (
                            <Button
                                type="primary"
                                className="btnSend"
                                onClick={eventUpdateQuote}
                            >
                                Save
                            </Button>
                        )}
                        {optionEvent === 'Delete' && (
                            <Button
                                type="primary"
                                className="btnSend"
                                onClick={deleteComment}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </div>
            </Spin>
        </ModalEditCommentStyle>
    );
};

export default ModalEditComment;
