import styled from '@emotion/styled';

interface CarhireCardProps {
    mode?: any;
}

const CarHideCardStyle = styled('div')<CarhireCardProps>`
    page-break-inside: avoid;
    .titleFightPlan {
        color: #000;
        font-size: 20px;
        font-weight: 700;
    }
    @media print {
        .traditionView {
            box-shadow: none;
        }
    }
    .traditionView {
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        display: flex;
        justify-content: space-between;
        border: ${(props) =>
            props.mode === 'Edit mode' ? '2px solid #12b897' : 'none'};
        margin-bottom: ${(props) =>
            props.mode === 'Edit mode' ? '0px' : '20px'};
    }
    .iconAddComment {
        width: 100%;
        display: ${(props) => (props.mode === 'Edit mode' ? 'flex' : 'none')};
        justify-content: flex-end;
        position: relative;
        bottom: 100px;
        left: 15px;
        cursor: pointer;
    }
    .iconAdd {
        width: 35px;
        height: 35px;
    }
    .iconAdd:hover {
        transform: scale(1.1);
    }
    .viewDetailPlanFlight {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .headerCard {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .viewFlightinformation {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        justify-content: end;
        margin-top: 16px;
        align-items: end;
    }
    .iconTransportation {
        color: #12b897;
    }
    .iconTransportationDiscript {
        color: #12b897;
        margin-right: 12px;
        margin-top: 4px;
    }
    .detailPlanflight {
        display: flex;
        align-items: flex-start;
        margin-top: 16px;
    }
    .textFont {
        color: #000;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
    }
    .textTimeFont {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
    }
    .locationName {
        width: 100%;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 22.2px;
    }
`;

export default CarHideCardStyle;
