import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import AgentComentInPricingStyle from './style';
import ModalComponent from '../modalCustom';
import ModalEditComment from './ModalconfirmEdit';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';
import { Popover, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';

interface AgentCommentProps {
    dataAgentComment?: any;
    showEdit?: boolean;
    itemId?: number | string;
    typeOfComent?: string;
    avatarAgent?: any;
    changeShowEdit?: () => void;
    refetch?: () => void;
    roleBtnEditOnHeader?: (state: any) => void;
    quoteTuorName?: any;
    quoteIntro?: any;
    quotePriceGroups?: any;
    additional?: any;
    loading?: any;
}

const PricingBookingAndAdditionalAgentComment: React.FC<AgentCommentProps> = ({
    dataAgentComment,
    showEdit,
    avatarAgent,
    changeShowEdit,
    refetch,
    roleBtnEditOnHeader,
    quoteTuorName,
    quoteIntro,
    quotePriceGroups,
    additional,
    loading,
}) => {
    const [value, setValue] = useState('');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [optionEvent, setOptionEvent] = useState<string>('');
    const [load, setLoad] = useState<boolean>(false);

    const isRole = useSelector(roleSelector);
    const dataComent = dataAgentComment;
    const handleDisable = value?.length || 0;

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['link'],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['blockquote', 'code-block'],
        ],
    };

    const formats = [
        'header',
        'script',
        'indent',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'list',
        'blockquote',
        'code-block',
    ];

    useEffect(() => {
        setValue(dataComent);
        setLoad(false);
    }, [dataComent]);

    const cancelEdit = () => {
        if (isRole === 'Edit mode' && dataComent) {
            changeShowEdit?.();
        }
        if (isRole === 'Edit mode' && !dataComent) {
            changeShowEdit?.();
        }
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const createComment = () => {
        if (handleDisable > 0) {
            setOptionEvent('Create');
            setIsModalVisible(true);
        }
    };

    const EditComent = () => {
        if (handleDisable > 0) {
            setOptionEvent('Edit');
            setIsModalVisible(true);
        }
    };

    const deleteComment = () => {
        setOptionEvent('Delete');
        setIsModalVisible(true);
    };

    const changeText = (e: any) => {
        const inputText = e.target.value;
        if (inputText?.length <= 250) {
            setValue(inputText);
        }
    };

    const updateIntro = (
        value: string,
    ) => {
        setValue(value);
    };

    return (
        <AgentComentInPricingStyle mode={handleDisable} addition={additional}>
            {!additional ? (
                <div>
                    {showEdit ? (
                        <div className="reviewSellerAgentComent">
                            <div className="reviewSellerInput">
                                <div className="viewInputEdit">
                                    <div className="areaMaxlenght">
                                        <div className="viewMaxLenght">
                                            {handleDisable}/250
                                        </div>
                                    </div>
                                    <TextArea
                                        className="textAreaComment"
                                        value={value}
                                        onChange={(e) => changeText(e)}
                                        placeholder="Type your message here..."
                                        autoSize={{
                                            minRows: 3,
                                            maxRows: 5,
                                        }}
                                        maxLength={250}
                                    />
                                </div>
                                <div className="avatarSeller">
                                    {avatarAgent ? (
                                        <div className="backgroundAvatar">
                                            <img
                                                alt=""
                                                className="avatarReals"
                                                src={avatarAgent}
                                            />
                                        </div>
                                    ) : (
                                        <FontAwesomeIcon
                                            className="avatar"
                                            icon={faCircleUser}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="btnEditComment">
                                <div className="btnLeft">
                                    {dataComent ? (
                                        <div
                                            className="btnSave"
                                            onClick={() => EditComent()}
                                        >
                                            Save
                                        </div>
                                    ) : (
                                        <div
                                            className="btnSave"
                                            onClick={() => createComment()}
                                        >
                                            Save
                                        </div>
                                    )}
                                    {dataComent && (
                                        <div
                                            className="btnDelete"
                                            onClick={() => deleteComment()}
                                        >
                                            Delete
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="btnDelete"
                                    onClick={() => cancelEdit()}
                                >
                                    Cancel
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Popover
                            placement="rightTop"
                            trigger={
                                isRole === 'Edit mode' && dataComent && 'hover'
                            }
                            content={'Click to edit'}
                        >
                            <Spin spinning={load} delay={500}>
                                <div
                                    className="reviewSeller"
                                    onClick={cancelEdit}
                                >
                                    <div className="viewDataComment">
                                        "{dataComent}"
                                    </div>
                                    <div className="avatarSeller">
                                        {avatarAgent ? (
                                            <div className="backgroundAvatar">
                                                <img
                                                    alt=""
                                                    className="avatarReals"
                                                    src={avatarAgent}
                                                />
                                            </div>
                                        ) : (
                                            <FontAwesomeIcon
                                                className="avatar"
                                                icon={faCircleUser}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Spin>
                        </Popover>
                    )}
                </div>
            ) : (
                <div className="addtionalView">
                    {showEdit ? (
                        <div>
                            <div className="titleAdditional">{additional}</div>
                            <div className="contentEdit">
                                <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    style={{
                                        width: '100%',
                                        height: '82%',
                                        position: 'relative',
                                        bottom: '21px',
                                    }}
                                    value={value}
                                    onChange={updateIntro}
                                />
                            </div>
                            <div className="btnEditCommentAdditional">
                                <div className="btnLeft">
                                    {dataComent ? (
                                        <div
                                            className="btnSave"
                                            onClick={() => EditComent()}
                                        >
                                            Save
                                        </div>
                                    ) : (
                                        <div
                                            className="btnSave"
                                            onClick={() => createComment()}
                                        >
                                            Save
                                        </div>
                                    )}
                                    {dataComent && (
                                        <div
                                            className="btnDelete"
                                            onClick={() => deleteComment()}
                                        >
                                            Delete
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="btnDelete"
                                    onClick={() => cancelEdit()}
                                >
                                    Cancel
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Spin spinning={load} delay={500}>
                                <div className="titleAdditional">
                                    {additional}
                                </div>
                                <div className="viewDataComment viewCommentforAdditionalInfor ">
                                    {parse(String(dataComent || ''))}
                                </div>
                            </Spin>
                        </div>
                    )}
                </div>
            )}
            <ModalComponent
                width={525}
                visible={isModalVisible}
                onClose={handleClose}
                title={'Confirmation'}
                content={
                    <ModalEditComment
                        close={handleClose}
                        quoteTuorName={quoteTuorName}
                        changeShowEdit={changeShowEdit}
                        refetch={refetch}
                        commentValue={value}
                        optionEvent={optionEvent}
                        additional={additional}
                        resetLoad={() => setLoad(true)}
                    />
                }
            />
        </AgentComentInPricingStyle>
    );
};

export default PricingBookingAndAdditionalAgentComment;
