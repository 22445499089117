import styled from '@emotion/styled';

interface GolfCardProps {
    mode?: any;
}

const GolfsCardStyle = styled('div')<GolfCardProps>`
    /* margin-bottom: 20px; */
    page-break-inside: avoid;
    .mobileView {
        display: none;
    }
    .iconHotelorGolf {
        color: #12b897;
    }
    .iconDetail {
        color: #12b897;
    }
    .discriptRoundOfGofl {
        display: flex;
        margin-bottom: 10px;
        width: 100%;
        align-items: center;
    }
    .iconDetailGolf {
        visibility: hidden;
        margin-right: 15px;
    }
    .endIcon {
        margin-left: 5px;
    }
    @media print {
        .hotelAndGoflView {
            box-shadow: none;
        }
    }
    .hotelAndGoflView {
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        display: flex;
        justify-content: space-between;
        margin-bottom: ${(props) =>
            props.mode === 'Edit mode' ? '0px' : '20px'};
        border: ${(props) =>
            props.mode === 'Edit mode' ? '2px solid #12b897' : 'none'};
    }
    .iconAddComment {
        width: 100%;
        display: ${(props) => (props.mode === 'Edit mode' ? 'flex' : 'none')};
        justify-content: flex-end;
        position: relative;
        bottom: 122px;
        left: 15px;
        cursor: pointer;
    }
    .iconAdd {
        width: 35px;
        height: 35px;
    }
    .iconAdd:hover {
        transform: scale(1.1);
    }
    .titleItem {
        margin-bottom: 20px;
        line-height: 23px;
    }
    .imgHotelOrGofl {
        width: 23.5%;
        height: 177px;
        gap: 16px;
        border-radius: 6px;
        margin-right: 24px;
        object-fit: cover;
    }
    .bodyAccom{
        width: 70%;
    }
    .viewInforGolf {
        display: flex;
        width: 85%;
    }
    .reviewSeller {
        width: 100%;
        justify-content: space-between;
    }
    .viewItemDescription {
        width: 100%;
    }
    .timeAndExtraRoundOfGolf {
        display: grid;
        grid-template-columns: 7% 50% 40%;
        gap: 10px;
        margin-bottom: 10px;
        text-align: left;
    }
    .hiddenItem {
        visibility: hidden;
    }
    .extrabuggy {
        width: 100%;
        text-align: left;
    }
    .detailRoundGolf {
        display: flex;
        /* justify-content: end; */
    }
    @media screen and (max-width: 1200px) {
        .hotelAndGoflViewMobile {
            display: none;
        }
    }
    @media screen and (max-width: 768px) {
        .desktopView {
            display: none;
        }
        .mobileView {
            display: block;
        }
        .hotelAndGoflViewMobile {
            display: block;
            border-radius: 4px;
            background: #fff;
            box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
            margin-bottom: 27px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        .imgHotelOrGofl {
            width: 100%;
            height: 223px;
            object-fit: cover;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        .bodyViewMobileCard {
            padding: 19px;
        }
        .headerMobile {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
        .contentInfor {
            margin-top: 25px;
            line-height: 20px;
        }
        .discriptRoundOfGofl {
            display: grid;
            grid-template-columns: 7% 41% 28%;
            gap: 10px;
            text-align: left;
        }
        .btnSeeDetail {
            margin-top: 20px;
        }
        .reviewSeller {
            width: 100%;
            justify-content: space-between;
            padding: 20px 30px 20px 30px;
            gap: 0px;
        }
    }
    @media screen and (max-width: 439px) {
        .discriptRoundOfGofl {
            display: grid;
            grid-template-columns: 10% 52% 32%;
            gap: 10px;
            text-align: left;
        }
    }
    @media screen and (max-width: 390px) {
        .discriptRoundOfGofl {
            display: grid;
            grid-template-columns: 5% 53% 35%;
            gap: 10px;
            text-align: left;
        }
    }
`;

export default GolfsCardStyle;
