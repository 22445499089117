import { AnyAction } from 'redux';
import * as ActionType from '../actions/clientNameAction';

export interface clientNameState {
    clientName: any;
}
const initialState: clientNameState = {
    clientName: "",
};
export const clientNameReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case ActionType.UPDATE_CLIENTNAME_SUCCESS:
            return {
                ...state,
                clientName: action.payload ,
            };
        default:
            return state;
    }
};
