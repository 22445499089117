import React, { useState } from 'react';
import StepComponentStyle from './style';
import icons from 'src/assets/icon';
import { useLocation } from 'react-router-dom';
import { formatPhoneNumber } from 'src/const/contain';
import ModalComponent from 'src/components/modalCustom';
import { ModalSendMessenge } from './modalSendMessenge';

interface stepProps {
    phone?: any;
    checkEvent?: any;
    lastName?: any;
}

const StepComponent: React.FC<stepProps> = ({
    phone,
    checkEvent,
    lastName,
}) => {
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const urlContainsPDFPages = location.pathname.includes('PDFPages');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const type = queryParams.get('type');
    const idIntro = localStorage.getItem('idIntro');

    const eventRedirectToPay = () => {
        window.open(
            `${process.env.REACT_APP_URL_PAYMENT}/quotes/QV${type?id:idIntro}`,
            '_blank',
        );
    };

    const phoneCall = () => {
        window.location.href = `tel:${phone || '08000436644'}`;
    };

    const openModal = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    return (
        <StepComponentStyle modePDF={urlContainsPDFPages}>
            <div className="groupWhyItem">
                <img alt="" className="iconWhy" src={icons.step01} />
                <div className="contentStep">
                    <div className="nextStepTitle">Any questions?</div>
                    <div className="nextStepDiscription">
                        Call me on{' '}
                        {!urlContainsPDFPages ? (
                            <span className="link" onClick={phoneCall}>
                                {phone
                                    ? formatPhoneNumber(phone)
                                    : formatPhoneNumber('08000436644')}
                            </span>
                        ) : (
                            <a
                                className="link"
                                href={`tel:${phone || '08000436644'}`}
                            >
                                {phone
                                    ? formatPhoneNumber(phone)
                                    : formatPhoneNumber('08000436644')}
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div className="groupWhyItem">
                <img alt="" className="iconWhy" src={icons.step02} />
                <div className="contentStep">
                    <div className="nextStepTitle">Ready to Book?</div>
                    <div className="nextStepDiscription">
                        {!urlContainsPDFPages ? (
                            <span className="link" onClick={eventRedirectToPay}>
                                Pay your deposit here{' '}
                            </span>
                        ) : (
                            <a
                                className="link"
                                href={`${process.env.REACT_APP_URL_PAYMENT}/quotes/QV${type?id:idIntro}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Pay your deposit here{' '}
                            </a>
                        )}
                        to secure this quote.
                    </div>
                </div>
            </div>
            <div className="groupWhyItem">
                <img alt="" className="iconWhy" src={icons.step03} />
                <div className="contentStep">
                    <div className="nextStepTitle">
                        Need to amend this Quote?
                    </div>
                    <div className="nextStepDiscription">
                        {!urlContainsPDFPages ? (
                            <span className="link" onClick={openModal}>
                                Let me know what you need,
                            </span>
                        ) : (
                            <span>
                                Let me know what you need,
                            </span>
                        )}{' '}
                        and I'll start working on it for you.
                    </div>
                </div>
            </div>
            <div className="groupWhyItem">
                <img alt="" className="iconWhy" src={icons.finalStep} />
                <div className="contentStep">
                    <div className="nextStepTitle">
                        This quote is only valid for 48 hours.
                    </div>
                    <div className="nextStepDiscription">
                        Availability is limited, so act quickly to secure your
                        trip!
                    </div>
                </div>
            </div>
            <ModalComponent
                width={525}
                visible={isModalVisible}
                onClose={handleClose}
                title={`Send ${lastName} a Message About Your Quote`}
                content={
                    <ModalSendMessenge close={() => setIsModalVisible(false)} />
                }
            />
        </StepComponentStyle>
    );
};

export default StepComponent;
