import React from 'react';
import ModalTouristDetinationStyle from './style';
import images from 'src/assets/image';
import { marked } from 'marked';
import parse from 'html-react-parser';
import { modifyURL } from 'src/const/contain';

interface ModalTourDestination{
    image?:string,
    discription?: string
}

const ModalTouristDetination: React.FC<ModalTourDestination> = ({image,discription}) => {

    const htmlContent = marked(discription||'');

    return (
        <ModalTouristDetinationStyle>
            <img alt="" className="imgLocation" src={image?modifyURL(image,1000):images.hotelImgFull} />
            <div className='content'>
                {parse(String(htmlContent))}
            </div>
        </ModalTouristDetinationStyle>
    );
};

export default ModalTouristDetination;
