import styled from '@emotion/styled';

const ViewQuoteIntroStyle = styled('div')`
    .editViewInyto {
        width: 90%;
    }
    .contentEdit {
        width: 100%;
        /* height: 300px; */
        padding: 10px;
        display: inline-flex;
        align-items: center;
        border: 2px solid #12b897;
    }
    .areaEdit {
        width: 100%;
        /* position: relative; */
        /* bottom: 329px; */
        display: flex;
        justify-content: end;
    }
    a {
        text-decoration-line: underline;
        color: #1a91ff;
    }
    .validateViewMainIntro {
        width: 61px;
        height: 30px;
        color: #12b897;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        border: 1px solid #12b897;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(18, 184, 151, 0.1);
    }
`;

export default ViewQuoteIntroStyle;
