import styled from '@emotion/styled';

const QouteNavigateStyle = styled('div')`
    width: 100%;
    .inforSeller {
        background-color: #ffffff;
        flex-shrink: 0;
        border-radius: 8px;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 17px 20px 17px 20px;
        margin-bottom: 20px;
    }
    .viewinforSeller {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .contentSeller {
        width: 60%;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.2px;
    }
    .avatarSeller {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .avatar {
        width: 87.065px;
        height: 89px;
        color: #12b897;
        object-fit: cover;
    }
    .avatarImage {
        width: 87.065px;
        height: 89px;
        color: #12b897;
        object-fit: cover;
        border-radius: 45px;
    }
    .backgroundAvatar {
        width: 93px;
        height: 95px;
        display: flex;
        background-color: #12b897;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
    }
    .btnPhoneCall {
        width: 100%;
        height: 56px;
        border-radius: 6px;
        font-style: 16px;
        background-color: #12b897;
        font-weight: 900 !important;
        font-family: 'Lato';
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media print {
        .btnPhoneCall {
            width: 100%;
            height: 56px;
            border-radius: 6px;
            font-style: 16px;
            background-color: #12b897 !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            font-weight: 900 !important;
            font-family: 'Lato';
            color: #ffffff !important;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .btnLink {
        color: #ffffff;
        font-style: 16px;
    }
    .btnPhoneCall:hover {
        background: #12b999 !important;
    }
    .inforPayMent {
        background-color: #ffffff;
        flex-shrink: 0;
        border-radius: 8px;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 17px 20px 17px 20px;
        margin-bottom: 20px;
    }
    .viewinforPayment {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        line-height: 34.516px;
        align-items: center;
    }
    .price {
        padding-top: 16px;
        margin-top: 27px;
        border-top: 1px solid #dcdcdc;
        font-size: 16px;
        line-height: 34.516px;
    }
    .iconPlus {
        cursor: pointer;
        width: 23px;
        height: 23px;
    }
    .iconPlus:hover {
        transform: scale(1.05);
    }
    .totalPrice {
        font-size: 18.657px;
        font-weight: 700;
        line-height: 34px;
    }
    .totalDeposit {
        font-size: 16px;
        font-weight: 700;
        line-height: 21.456px;
    }
    .viewPrice {
        display: flex;
        justify-content: space-between;
    }
    .numTotalPrice {
        font-size: 22.389px;
        font-weight: 900;
    }
    .totalDeposit {
        font-size: 16px;
        font-weight: 900;
        display: flex;
        align-items: center;
    }
    .numGroups {
        font-size: 16px;
        font-weight: 900;
    }
    .contentNavigte {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-decoration-line: underline;
        color: #1a91ff;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
    }
    .contentNavigteFocus {
        color: #000;
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
    }
    .arrowLeft {
        margin-right: 5px;
    }
    .contentNavigte:hover {
        transform: scale(1.05);
    }
    .groupsBtn {
        display: flex;
        flex-direction: column;
    }
    .btnDownload {
        color: #12b897;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        font-family: 'Lato';
        line-height: 17.867px;
        border-radius: 6px;
        border: 2px solid #12b897;
        margin-bottom: 13px;
        padding-top: 18px;
        padding-bottom: 18px;
        width: 100%;
    }
    .spinDownload {
        height: 83% !important;
    }
    .btnDownload:hover {
        color: #12b897 !important;
        border-radius: 6px !important;
        border: 2px solid #12b897 !important;
    }
    .btnShare {
        color: #12b897;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        font-family: 'Lato';
        line-height: 17.867px;
        border-radius: 6px;
        border: 2px solid #12b897;
        padding-top: 18px;
        padding-bottom: 18px;
    }
    .btnShare {
        color: #12b897 !important;
        border-radius: 6px !important;
        border: 2px solid #12b897 !important;
    }
    .nameGolfer {
        color: #454545;
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
    }
    .nameGroups {
        color: #454545;
        font-size: 16px;
        font-weight: 700;
        line-height: 34.516px;
        margin-right: 6px;
    }
    .groupComponent {
        display: flex;
    }
    .title {
        color: #454545;
        font-size: 16px;
        font-weight: 400;
        line-height: 34.516px;
        letter-spacing: 0.16px;
    }
    .inforGroup {
        padding: 10px 16px 10px 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .iconDetail {
        color: #12b897;
    }
    .detailInfor {
        color: #4f4f4f;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.16px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    .titleDetailInfor {
        width: 90%;
        text-align: left;
        display: flex;
        align-items: baseline;
    }
    .totalDiscount {
        color: #1877f2;
        font-size: 16px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0.16px;
    }
    .numTotalDiscount {
        color: #1877f2;
        text-align: right;
        font-size: 16px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0.16px;
    }
    @media screen and (max-width: 1200px) {
        .arrowLeft {
            display: none;
        }
    }
    @media screen and (max-width: 380px) {
        .nameGroups {
            color: #454545;
            font-size: 14px;
            font-weight: 700;
            line-height: 34.516px;
            margin-right: 6px;
        }
        .title {
            color: #454545;
            font-size: 14px;
            font-weight: 400;
            line-height: 34.516px;
            letter-spacing: 0.16px;
        }
        .numGroups {
            font-size: 14px;
            font-weight: 900;
        }
    }

    @media screen and (max-width: 768px) {
        .contentSeller {
            width: 60%;
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
        }
        .btnPhoneCall {
            height: 40px;
            font-size: 14px;
            font-family: 'Lato';
        }
        .contentNavigteFocus {
            font-size: 15px;
            line-height: 13px;
            margin-bottom: 8px;
        }
        .contentNavigte {
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 5px;
        }
    }
`;

export default QouteNavigateStyle;

export const ModalCopyLink = styled('div')`
    .ant-input-search-button {
        background-color: #12b897 !important;
        font-family: 'Lato';
    }
    .contenShare {
        display: flex;
        align-items: center;
        margin-top: 24px;
    }
    .inputCopy {
        margin-top: 20px;
    }
    .faCircleInfo {
        color: #12b897;
        font-size: 15px;
        font-weight: 900;
        margin-right: 6px;
    }
`;
